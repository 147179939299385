import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsheetService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/jobsheet`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/jobsheet/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/jobsheet/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/jobsheet`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/jobsheet/`+id);
    }
    
    upload(id,formData){
      return this.http.post(`${environment.baseUrl}/jobsheet/`+id+`/upload`,formData);
    }

    start(id, formData){
      return this.http.post(`${environment.baseUrl}/jobsheet/`+id+`/start`,formData);
    }

    finish(id,formData){
      return this.http.post(`${environment.baseUrl}/jobsheet/`+id+`/finish`,formData);
    }

    deleteFile(fileId){
      return this.http.delete(`${environment.baseUrl}/jobsheet/document/`+fileId);
    }

    getStatuses(){
      return this.http.get(`${environment.baseUrl}/jobsheet/status`);
    }

    getTypes(){
      return this.http.get(`${environment.baseUrl}/jobsheet/type`);
    }

    getPDF(id,download){
      return this.http.get(`${environment.baseUrl}/jobsheet/`+id+`/pdf`,{params:{
        download:download
      }});
    }

    mail(id,email){
      return this.http.get(`${environment.baseUrl}/jobsheet/`+id+'/email?email='+email);
    }

    reference(){
      return this.http.get(`${environment.baseUrl}/jobsheet/reference`);
    }

}
