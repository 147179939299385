import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { ClientService } from '../../../shared/services/client.service';
import { ClientModel } from '../../../shared/models/client.model';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

  @Input() external:boolean = false;
  @Output() saveEvent:EventEmitter<any> = new EventEmitter<any>();
  client: ClientModel;
  clientForm: FormGroup;
  roles: any[];
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  constructor(
    private cdr: ChangeDetectorRef,
    private clientService: ClientService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private _location: Location
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadLocalities();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadclient(res['params']['id']);
      }
    })
  }

  menuItems: any[] = [
    { action: 'jobsheets', message: 'View Jobsheets', class: '', icon: 'fal fa-clipboard-list', permissions: ['view_jobsheet'] },
    { action: 'delete', message: 'Delete Client', class: '', icon: 'fal fa-trash', permissions: ['delete_client'] }
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.mdSlideUp.show();
        break;
        case "jobsheets":
          this.router.navigate(['/jobsheet'],{queryParams:{cId:this.client.id}})
          break;
      default:
        console.log(event);
        break;
    }
  }

  loadLocalities() {
    let _this = this;
    // this.localityService.getAll().subscribe(res => {
    //   _this.localities = res['data'];
    // },
    // err => {
    //   console.log(err);
    // })
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("client-create")) {
      this.createForm(JSON.parse(localStorage.getItem("client-create")));
      // if (this.clientForm.get('phone').value) {
      //   this.clientForm.get('phone').setValue(this.clientForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.clientForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("client-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.clientForm.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.cdr.detectChanges();
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  createForm(input?) {
    input = input || {};
    this.clientForm = this.fb.group({
      first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [input.email, [Validators.email, Validators.required]],
      vat_no: [input.vat_no],
      address_1: [input.address_1],
      address_2: [input.address_2],
      post_code: [input.post_code],
      town: [input.town],
      country: [input.country],
      //company: [input.company],
      company_name: [input.company_name],
      contact_person: [input.contact_person],
      contact_person_email: [input.contact_person_email],

      //vat_no: [input.vat_no],
      phone_number: [input.phone_number]
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  uploadFile(fileInput) {
    console.log(fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e.target.result);
        //$('#preview').attr('src', e.target.result);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  isTouched(controlName) {
    if (this.clientForm.get(controlName).touched && this.clientForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadclient(id) {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.clientService.get(id).subscribe(res => {
      _this.client = res['data'];
      _this.createForm(_this.client);
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.clientService.delete(this.client.id).subscribe(res => {
      this.router.navigate(['/client']);
      this.messageService.remove();
      this.messageService.success("client Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.mdSlideUp.show();
  }

  @Input() isLoading:boolean = false;
  save() {
    this.clientForm.updateValueAndValidity();
    if (this.clientForm.invalid) {
      this.clientForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if(this.isLoading){
      return;
    }

    localStorage.removeItem("client-create");
    let _this = this;

    _this.isLoading = true;

    let rawValue = this.clientForm.getRawValue();
    this.clientForm.markAsUntouched();
    this.clientForm.updateValueAndValidity();
    if (this.client) {
      this.clientService.edit(this.client.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Client Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(res['data']);
        _this.createForm(res['data']);
        console.log(_this.clientForm);
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.clientService.create(rawValue).subscribe(res => {
        if(_this.external == true){
          _this.saveEvent.emit(res['data']);
        }
        else{
          this.router.navigate(['/client/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("Client Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }


}
