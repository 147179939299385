import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { pagesToggleService } from '../../services/toggler.service';
import { RootLayout } from '../root/root.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { NotificationModel } from '../../../shared/models/notification.model';
import * as moment from 'moment';
import { NotificationService } from '../../../shared/services/notification.service';
import { WebsocketService } from '../../../shared/services/websocket.service';
import { Subject } from 'rxjs';
import { Utils } from '../../../shared/services/utils.service';
import { SubscriptionService } from '../../../shared/services/subscription.service';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit, OnDestroy {

  constructor(
    public toggler: pagesToggleService,
    public router: Router,
    public permissionsService: NgxPermissionsService,
    private store: LocalStoreService,
    private notificationService: NotificationService,
    private subService : SubscriptionService,
    private utils : Utils,
    private cdr: ChangeDetectorRef,
    private wsService: WebsocketService,
    private authService: AuthService) {
    super(toggler, router);
  }

  ngOnInit() {
    let _this = this;
    this.filterMenu();
    if(!this.authService.didFirstCheck){
      this.init();
      this.authService.didFirstCheck = true;
    }
    this.wsService.listenChannel("private-user-channel-",this.authService.getUserId(),this.cdr);

    this.notificationService.onUpdate.subscribe(res => {
      console.log(res);
      _this.cdr.detectChanges();
    },
    err => {
      console.log(err);
    })

    this.subService.cardsUpdated.subscribe(res => {
      console.log(res);
    });
  }

  init(){
    let _this = this;
    this.changeLayout('menu-pin');
    this.autoHideMenuPin();
    this.loadNotifications(false);
    let timeout = setInterval(() => {
      if (_this.authService.authenticated) {
        clearTimeout(timeout);
      }
      else{
        _this.authService.checkAuth(_this.cdr);
      }
    }, 1000);
    // setTimeout(() => {
    //   _this.authService.checkAuth();
    // }, 500);
    if (!this.authService.triggered) {
      this.authService.triggered = true;
      this.authService.initialTimeout = setTimeout(() => {
        _this.authService.initialTimeout = null;
      }, 2000);
    }
  }

  handleSocket(res) {
    console.log(res);
  }

  getNotifications(){
    return this.notificationService.notifications;
  }

  loadNotifications(isLoadMore) {
    this.notificationService.loadNotifications(isLoadMore);
  }

  readAll() {
    this.notificationService.readAllNotifications();
  }

  markRead(notification: NotificationModel) {
    if(notification.read_at){
      return;
    }
    notification.read_at = moment(new Date()).format();
    if(this.notificationService.has_unread > 0){
      this.notificationService.has_unread--;
    }
    this.notificationService.markRead(notification);
  }

  activateNotification(notification) {
    this.markRead(notification);
    switch (notification.description.split(" ")[0].toLowerCase()) {
      case "stock":
        this.router.navigate(['/stock/' + notification.properties.stock]);
        break;
      case "invoice":
        this.router.navigate(['/invoice/' + notification.properties.invoice]);
        break;
      case "jobsheet":
        this.router.navigate(['/jobsheet/' + notification.properties.jobsheet]);
        break;
    }
  }

  getNotificationClasses(notification: NotificationModel) {
    let classes: string[] = [];
    classes.push(notification.notification_type);
    return classes;
    //{'unread':!notification.read_at,notification.type};
  }

  hasUnreadNotification() {
    return this.notificationService.has_unread > 0;
  }

  getUnread(){
    return this.notificationService.has_unread;
  }

  getHumanTime(date) {
    //console.log(asset.gps.timestamp);
    return moment.duration(moment(new Date()).diff(date)).humanize() + " ago";
  }

  filterMenu() {
    if (!this.authService.permissions) {
      if (this.store.getItem('currentUser')) {
        this.authService.permissions = this.store.getItem('currentUser')['permissions'];
      }
    }
    if (this.authService.permissions) {
      this.permissionsService.loadPermissions(this.authService.permissions);
    }
    for (let parent = 0; parent < this.menuLinks.length; parent++) {
      if (!this.authService.hasPermission(this.menuLinks[parent].roles)) {
        this.menuLinks[parent] = null;
      }
    }
    this.menuLinks = this.menuLinks.filter(Boolean);
  }

  logout() {
    this.authService.signout();
  }

  isGraceMode(){
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    return this.store.getItem('currentUser')['grace'];
  }

  isExpiredMode(){
    if(this.expiredModeHidden){
      return null;
    }
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    if(this.store.getItem('currentUser')['company']['renewal']){
      return this.store.getItem('currentUser')['company']['renewal']['expiry_date'];
    }
    return null;
  }

  expiredModeHidden:boolean = false;
  hideExpiredMode(){
    this.expiredModeHidden = true;
    let updatedUser = this.store.getItem('currentUser');
    updatedUser['company']['renewal'] = null;
    this.store.setItem('currentUser',updatedUser);
    console.log(this.store.getItem('currentUser')['company']);
  }

  isLoadingNotifications(){
    return this.notificationService.notificationSub;
  }

  onScroll(element){
    let toBottom = element.target.scrollHeight - element.target.scrollTop === element.target.clientHeight;
    if(toBottom){
      this.notificationService.loadNotifications(true);
    }
  }

  getUsername() {
    if (!this.authService.user) {
      return "";
    }
    return this.authService.user.first_name + ' ' + this.authService.user.last_name;
  }

  getLogo() {
    return this.authService.getLogo();
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  companyProfile() {
    this.router.navigate(['/company/profile']);
  }

  subscriptionModalTrigger:EventEmitter<any> = new EventEmitter<any>();
  openSubscriptionModal(){
    this.subscriptionModalTrigger.emit(true);
  }

  cardModalTrigger:EventEmitter<any> = new EventEmitter<any>();
  openCardModal(){
    this.cardModalTrigger.emit(true);
  }

}
