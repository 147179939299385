import { ClientModel } from "./client.model";
import { InstallationModel } from "./installation.model";
import { UserModel } from "./user.model";

export class JobsheetModel{

    id: number;
    reference:string;
    status: {
        id:number,
        title:string
    };
    description: string;
    sub_total: number;
    vat_total: number;
    total_price: number;
    actual_start_at: string;
    actual_finish_at: string;
    scheduled_start_at:string;
    scheduled_finish_at:string;
    files:any[];
    items:any[];
    installation:InstallationModel;
    assigned_employees:UserModel[];
    client:ClientModel;
    signature_file_path:string;
    signed_at:string;
    signed_by:string;
    signer_id:string;

    constructor(input?){
        input = input || {};
        this.id = input.id || '';
        this.reference = input.reference || '';
        this.status = input.status || '';
        this.description = input.description || '';
        this.sub_total = input.sub_total || '';
        this.vat_total = input.vat_total || '';
        this.total_price = input.total_price || '';
        this.actual_start_at = input.actual_start_at || '';
        this.actual_finish_at = input.actual_finish_at || '';
        this.scheduled_start_at = input.scheduled_start_at || '';
        this.scheduled_finish_at = input.scheduled_finish_at || '';
        this.installation = input.installation || {};
        this.assigned_employees = input.assigned_employees || '';
        this.files = input.files || '';
        this.items = input.items || '';
        this.client = input.client || '';
        this.signature_file_path = input.signature_file_path || '';
        this.signed_at = input.signed_at || '';
        this.signed_by = input.signed_by || '';
        this.signer_id = input.signer_id || '';
    }
}