import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { ActionModel, MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { JobsheetModel } from '../../../shared/models/jobsheet.model';
import { JobsheetService } from '../../../shared/services/jobsheet.service';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import * as moment from 'moment';
import { ClientModel } from '../../../shared/models/client.model';
import { Subscription } from 'rxjs';
import { ClientService } from '../../../shared/services/client.service';
import { UserModel } from '../../../shared/models/user.model';
import { UserService } from '../../../shared/services/user.service';
import { InstallationModel } from '../../../shared/models/installation.model';
import { InstallationService } from '../../../shared/services/installation.service';
import { Utils } from '../../../shared/services/utils.service';
import { StockModel } from '../../../shared/models/stock.model';
import { StockService } from '../../../shared/services/stock.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as FileSaver from 'file-saver';
import { NotificationService } from '../../../shared/services/notification.service';
import { DotMenuComponent } from '../../components/dot-menu/dot-menu.component';

@Component({
  selector: 'app-jobsheet-detail',
  templateUrl: './jobsheet-detail.component.html',
  styleUrls: ['./jobsheet-detail.component.scss']
})
export class JobsheetDetailComponent implements OnInit {

  @Input() jobsheet: JobsheetModel;
  jobsheetForm: FormGroup;
  @ViewChild('jobsheetDelete', { static: true }) jobsheetDelete: ModalDirective;
  @ViewChild('signature', { static: true }) signature: ModalDirective;
  @ViewChild('deleteDocument', { static: true }) deleteDocument: ModalDirective;
  @ViewChild('uploadAttachment', { static: true }) uploadAttachment: ModalDirective;
  @ViewChild('sendPDF', { static: true }) sendpdf: ModalDirective;
  @ViewChild('addClientModal', { static: true }) clientModal: ModalDirective;
  @ViewChild('deleteStock', { static: true }) deleteStock: ModalDirective;
  @ViewChild('addInstallationModal', { static: true }) addInstallationModal: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;
  fromDate: Date = new Date();
  toDate: Date = moment(new Date()).add(1, 'hour').toDate();
  statuses: any[];
  @ViewChild(DatatableComponent, { static: true }) attachmentTable: DatatableComponent;

  signaturePadOptions: any = { // passed through to szimek/signature_pad constructor
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  //latestSignature: any;
  assigned_employees: UserModel[] = [];
  euroPrefix = createNumberMask({
    prefix: '€ ',
    suffix: '',
    allowDecimal: true
  });

  types: any[];

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private jobsheetService: JobsheetService,
    private _location: Location,
    private clientService: ClientService,
    private userService: UserService,
    private installationService: InstallationService,
    private Utils: Utils,
    private stockService: StockService,
    private notificationService: NotificationService
  ) { }

  @Input() jobsheetEvent: EventEmitter<any>;
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    // this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    let _this = this;
    this.createForm({});
    this.createSignerForm({});
    this.loadClients();
    this.loadUsers();
    this.loadStatuses();
    this.loadTypes();
    this.loadStock();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id']) {
        this.loadJobsheet(res['params']['id']);
      }
      else {
        _this.preloadReference();
      }
    })
    this.snapshot.queryParamMap.subscribe(res => {
      console.log(res);
      if (res['params']['scheduled_start_at']) {
        _this.fromDate = new Date(res['params']['scheduled_start_at']);
        _this.toDate = new Date(res['params']['scheduled_finish_at']);
        _this.jobsheetForm.get('scheduled_start_at').setValue(new Date(res['params']['scheduled_start_at']));
        _this.jobsheetForm.get('scheduled_finish_at').setValue(new Date(res['params']['scheduled_finish_at']));
        console.log("Dates Set!");
      }
    })
    //jobsheetEvent:EventEmitter<any> = new EventEmitter<any>();
    if (this.jobsheetEvent) {
      this.jobsheetEvent.subscribe(res => {
        if(_this.jobsheet){
          _this.jobsheet.files = null;
          _this.jobsheet.files = [];
          if(_this.attachmentTable){
            _this.attachmentTable.recalculateColumns();
          }
        }
        _this.jobsheet = res;
        _this.loadPreloadedJobsheet(res);
      })
    }
  }


  loadPreloadedJobsheet(res) {
    let _this = this;
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    _this.assigned_employees = res['assigned_employees'];
    if (res['status'] && res['status']['id']) {
      res['status'] = res['status']['id'];
    }
    if (res['installation'] && res['installation']['id']) {
      res['installation'] = res['installation']['id'];
    }
    if (res['client'] && res['client']['id']) {
      res['client'] = res['client']['id'];
    }
    res['assigned_employees'] = res['assigned_employees'] ? Array.from(res['assigned_employees']).map(val => (val['id'] ? val['id'] : val)) : null;
    _this.loadInstallations(res['client']);
    _this.stockItems = [];
    for (let item of res['items']) {
      _this.newStockItem(item);
    }

    if (res['actual_start_at']) {
      _this.fromDateActual = moment(res['actual_start_at']).toDate();
    }
    if (res['actual_finish_at']) {
      _this.toDateActual = moment(res['actual_finish_at']).toDate();
    }

    if (res['scheduled_start_at']) {
      _this.fromDate = moment(res['scheduled_start_at']).toDate();
    }
    if (res['scheduled_finish_at']) {
      _this.toDate = moment(res['scheduled_finish_at']).toDate();
    }
    _this.createForm(res);
    console.log(res);
    console.log(_this.jobsheet);
    _this.loading.isLoading = false;
    _this.tmpReference = null;
  }

  convertTo2DP(item: string, formIndex) {
    let value: number = +this.stockItems[formIndex].get(item).value.toString().replace(/[^0-9\.]+/g, "");
    this.stockItems[formIndex].get(item).setValue(value.toFixed(2));
  }

  tmpReference: number;

  preloadReference() {
    let _this = this;
    this.jobsheetService.reference().subscribe(res => {
      _this.tmpReference = res['data']['reference'];
    },
    err => {
      console.log(err);
    });
  }

  loadStatuses() {
    let _this = this;
    this.jobsheetService.getStatuses().subscribe(res => {
      console.log(res);
      _this.statuses = res['data'];
    },
      err => {
        console.log(err);
      })
  }

  loadTypes() {
    let _this = this;
    this.jobsheetService.getTypes().subscribe(res => {
      _this.types = res['data'];
    },
      err => {
        console.log(err);
      })
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete Jobsheet', class: '', icon: 'fal fa-trash', permissions: ['delete_jobsheet'] },
    { action: 'print', message: 'Print Jobsheet', class: '', icon: 'fal fa-print', permissions: [] },
    { action: 'download', message: 'Download', class: '', icon: 'fal fa-download', permissions: [] },
    { action: 'send', message: 'Send Jobsheet', class: '', icon: 'fal fa-envelope', permissions: [] },
    { action: 'subscribe', message: 'Subscribe', class: '', icon: 'fal fa-bell', permissions: [] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.jobsheetDelete.show();
        break;
      case "print":
        this.printPDF();
        //this.mdSlideUp.show();
        break;
      case "download":
        this.downloadPDF();
        //this.mdSlideUp.show();
        break;
      case "send":
        this.sendpdf.show();
        this.email = this.jobsheet.client.email;
        break;
      case "subscribe":
        this.subscribeToJobsheet();
        break;
      default:
        console.log(event);
        break;
    }
  }

  subscribeToJobsheet() {
    this.notificationService.linkToJobsheet(this.jobsheet.id).subscribe(res => {
      this.messageService.success("Subscribed Successfully.", { Position: 'top', Style: 'bar', Duration: 5000 });
    },
      err => {
        this.messageService.success("Could not subscribe to jobsheet..", { Position: 'top', Style: 'bar', Duration: 5000 });
      })
  }

  presetData(event, item: FormGroup) {
    for (let st of this.stock) {
      if (st.reference == event) {
        item.get('unit_price').setValue(st.sale_price);
        item.get('quantity').setValue(1);
        item.get('description').setValue(st.description);
        let vatOfSalePrice = st.sale_price * (st.tax_percentage / 100);
        item.get('vat_price').setValue(vatOfSalePrice);
        //item.vat_price = st.vat_price;
        break;
      }
    }
  }

  stockItems: FormGroup[];
  newStockItem(preloadedItem?) {
    let _this = this;
    let stockItemTemplate = this.fb.group({
      reference: ['Unlisted Item', Validators.required],
      description: ['Unlisted Item', Validators.required],
      quantity: [1, Validators.required],
      unit_price: [0, Validators.required],
      vat_price: [0, Validators.required],
    });

    if (preloadedItem) {
      stockItemTemplate = this.fb.group({
        deleted_at: [preloadedItem.deleted_at],
        id: [preloadedItem.id],
        reference: [preloadedItem.reference, Validators.required],
        description: [preloadedItem.description, Validators.required],
        quantity: [preloadedItem.quantity, Validators.required],
        unit_price: [preloadedItem.unit_price, Validators.required],
        vat_price: [preloadedItem.vat_price, Validators.required],
      });
    }

    if (!preloadedItem) {
      _this.isSaveRequired = true;
    }

    stockItemTemplate.get('reference').valueChanges.subscribe(newValue => {
      _this.presetData(newValue, stockItemTemplate);
    });
    stockItemTemplate.valueChanges.subscribe(newValue => {
      let priceValues = _this.generateTotalPrice();
      _this.jobsheetForm.get('sub_total').setValue(priceValues.total_price);
      _this.jobsheetForm.get('vat_total').setValue(priceValues.vat_price);
      _this.jobsheetForm.get('total_price').setValue((priceValues.vat_price + priceValues.total_price));
    })
    if (this.stockItems) {
      _this.stockItems.push(stockItemTemplate);
      _this.convertTo2DP('unit_price', (_this.stockItems.length - 1));
      _this.convertTo2DP('vat_price', (_this.stockItems.length - 1));
    }
    else {
      _this.stockItems = [stockItemTemplate];
      _this.convertTo2DP('unit_price', (_this.stockItems.length - 1));
      _this.convertTo2DP('vat_price', (_this.stockItems.length - 1));
    }
  }

  // getItemPrice(item: FormGroup) {
  //   let unit_price: number = !item.get('unit_price') ? 0 : +item.get('unit_price').value.toString().replace(/[^0-9\.]+/g, "");
  //   let vat_price: number = !item.get('vat_price') ? 0 : +item.get('vat_price').value.toString().replace(/[^0-9\.]+/g, "");
  //   let quantity: number = !item.get('quantity') ? 1 : item.get('quantity').value;
  //   return ((unit_price * quantity) + vat_price).toFixed(2);
  // }

  // generateTotalPrice() {
  //   let price: number = 0;
  //   let vat: number = 0;
  //   for (let item of this.stockItems) {
  //     let unit_price: number = !item.get('unit_price') ? 0 : +item.get('unit_price').value.toString().replace(/[^0-9\.]+/g, "");
  //     let vat_price: number = !item.get('vat_price') ? 0 : +item.get('vat_price').value.toString().replace(/[^0-9\.]+/g, "");
  //     let quantity: number = !item.get('quantity') ? 1 : item.get('quantity').value;
  //     price += unit_price * quantity;
  //     vat += vat_price;
  //   }
  //   return { total_price: price, vat_price: vat };
  // }

  // getTotalPrice(): any {
  //   let val: number = 0;
  //   let sub_total: number = !this.jobsheetForm.get('sub_total').value ? 0 : +this.jobsheetForm.get('sub_total').value.toString().replace(/[^0-9\.]+/g, "");
  //   let vat_total: number = !this.jobsheetForm.get('vat_total').value ? 0 : +this.jobsheetForm.get('vat_total').value.toString().replace(/[^0-9\.]+/g, "");
  //   val = +sub_total + vat_total;
  //   return val.toFixed(2);
  // }

  getItemPrice(item: FormGroup) {
    let unit_price: number = !item.get('unit_price') ? 0 : +item.get('unit_price').value.toString().replace(/[^0-9\.]+/g, "");
    let vat_price: number = !item.get('vat_price') ? 0 : +item.get('vat_price').value.toString().replace(/[^0-9\.]+/g, "");
    let quantity: number = !item.get('quantity') ? 1 : item.get('quantity').value;
    let val = ((unit_price * quantity) + (vat_price * quantity));
    return val.toFixed(2);
  }

  generateTotalPrice() {
    let price: number = 0;
    let vat: number = 0;
    for (let item of this.stockItems) {
      let unit_price: number = !item.get('unit_price') ? 0 : +item.get('unit_price').value.toString().replace(/[^0-9\.]+/g, "");
      let vat_price: number = !item.get('vat_price') ? 0 : +item.get('vat_price').value.toString().replace(/[^0-9\.]+/g, "");
      let quantity: number = !item.get('quantity') ? 1 : item.get('quantity').value;
      price += unit_price * quantity;
      vat += vat_price * quantity;
    }
    return { total_price: price, vat_price: vat };
  }

  getTotalPrice(): any {
    if (!this.jobsheet) {
      return 0;
    }
    let val: number = 0;
    let sub_total: number = !this.jobsheet.sub_total ? 0 : +this.jobsheet.sub_total;
    let vat_total: number = !this.jobsheet.vat_total ? 0 : +this.jobsheet.vat_total;
    val = +sub_total + vat_total;
    return +val.toFixed(2);
  }

  formatNumber(number) {
    let formattedNumber = this.Utils.formatNumbersToFixedWithCommas(number);
    return formattedNumber;
  }

  getSubtotal(): any {
    let val = this.jobsheetForm.get('sub_total').value;
    return +val;
  }

  getVat(): any {
    let vat = this.jobsheetForm.get('vat_total').value;
    return +vat;
  }

  drawComplete() {
    this.signerForm.get('signature').setValue(this.signaturePad.toDataURL());
  }

  hasSignature() {
    if (this.signerForm.get('signature')) {
      if (this.signerForm.get('signature').value) {
        return true;
      }
    }
    return false;
  }

  drawStart() {
    console.log('begin drawing');
  }

  forceRefresh(dateEvent) {
    let _this = this;
    if (dateEvent.date) {
      if (moment(new Date(dateEvent.date)).isBefore(_this.fromDate)) {
        dateEvent.date = _this.fromDate;
      }
      if (moment(new Date(dateEvent.date)).isAfter(_this.toDate)) {
        dateEvent.date = _this.toDate;
      }
    }
    // if (dateEvent.isStart == false) {
    //   _this.toDate = new Date(dateEvent.date)
    // }
    // else {
    //   _this.fromDate = new Date(dateEvent.date)
    // }
    if (!this.fromDate && !this.toDate) {
      return;
    }

    if (moment(this.fromDate).isAfter(moment(this.toDate))) {
      this.toDate = moment(this.fromDate).add(1, 'h').toDate();
      console.log(this.toDate);
    }

    if (this.fromPicker) {
      if (this.fromPicker.timePickerInner) {
        this.fromPicker.timePickerInner.updateTime();
      }
    }
    if (this.toPicker) {
      if (this.toPicker.timePickerInner) {
        this.toPicker.timePickerInner.updateTime();
      }
    }
    _this.cdr.detectChanges();
  }

  _disabledStartDate = (startValue) => {
    if (!startValue) {
      this.fromDate = moment(this.toDate).subtract(1, 'd').toDate();
    }
    if (!this.toDate) {
      this.toDate = moment(this.fromDate).add(1, 'd').toDate();
    }
    return moment(startValue).isAfter(moment(this.toDate));
  };

  _disabledEndDate = (endValue) => {
    if (!endValue || !this.fromDate) {
      this.toDate = moment(this.fromDate).add(1, 'd').toDate();
    }
    return moment(endValue).isBefore(moment(this.fromDate));
  };

  fromDateActual: Date = null;
  toDateActual: Date = null;

  forceRefreshActual(dateEvent) {
    let _this = this;
    if (dateEvent.date) {
      if (moment(new Date(dateEvent.date)).isBefore(_this.fromDateActual)) {
        dateEvent.date = _this.fromDateActual;
      }
      if (moment(new Date(dateEvent.date)).isAfter(_this.toDateActual)) {
        dateEvent.date = _this.toDateActual;
      }
    }
    // if (dateEvent.isStart == false) {
    //   return;
    //   //_this.toDateActual = new Date(dateEvent.date)
    // }
    // else {
    //   return;
    //   //_this.fromDateActual = new Date(dateEvent.date)
    // }
    if (!this.fromDateActual && !this.toDateActual) {
      return;
    }
    if (moment(this.fromDateActual).isAfter(moment(this.toDateActual))) {
      this.toDateActual = moment(this.fromDateActual).add(1, 'h').toDate();
      console.log(this.toDateActual);
    }
    if (this.fromPicker) {
      if (this.fromPicker.timePickerInner) {
        this.fromPicker.timePickerInner.updateTime();
      }
    }
    if (this.toPicker) {
      if (this.toPicker.timePickerInner) {
        this.toPicker.timePickerInner.updateTime();
      }
    }
    _this.cdr.detectChanges();
  }

  _disabledStartDateActual = (startValue) => {
    if (!startValue || !this.toDateActual) {
      return false;
    }
    return moment(startValue).isAfter(moment(this.toDateActual));
  };

  _disabledEndDateActual = (endValue) => {
    if (!endValue || !this.fromDateActual) {
      return false;
    }
    return moment(endValue).isBefore(moment(this.fromDateActual));
  };

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("jobsheet-create")) {
      this.createForm(JSON.parse(localStorage.getItem("jobsheet-create")));
      // if (this.jobsheetForm.get('phone').value) {
      //   this.jobsheetForm.get('phone').setValue(this.jobsheetForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.jobsheetForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("jobsheet-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.jobsheetForm.markAsTouched();
  }

  isSystemAdmin(): boolean {
    if (this.authService.getUserRole() == 1) {
      return true;
    }
    return false;
  }

  loadInstallations(value) {
    let _this = this;
    this.installations = [];
    this.jobsheetForm.get('installation').setValue(null);
    _this.clientService.getAll({ client: value }).subscribe(res => {
      if (res['data'][0]) {
        _this.jobsheetForm.get('installation').setValue(res['data'][0]['id']);
        _this.installations = [...res['data']];
        for (let installation of _this.installations) {
          installation['search_name'] = installation.reference + (installation.address_1 ? ' - ' + installation.address_1 : '');
          if (installation.deleted_at) {
            installation['search_name'] = installation.reference + (installation.address_1 ? ' - ' + installation.address_1 : '') + ' - (Deleted)';
          }
        }
        console.log(_this.installations);
      }
      else {
        this.messageService.error("This client has no installations.", { Position: 'top', Style: 'bar', Duration: 50000 });
      }
    },
      err => {
        console.log(err);
      });
  }

  installations: InstallationModel[] = [];
  isSaveRequired: boolean = false;
  createForm(input?) {
    let _this = this;
    input = input || {};
    this.jobsheetForm = this.fb.group({
      reference: [input.reference],
      description: [input.description],
      status: [input.status, Validators.required],
      sub_total: [input.sub_total],
      vat_total: [input.vat_total],
      total_price: [input.total_price],
      scheduled_start_at: [input.scheduled_start_at],
      scheduled_finish_at: [input.scheduled_finish_at],
      actual_start_at: [input.actual_start_at],
      actual_finish_at: [input.actual_finish_at],
      client: [input.client, Validators.required],
      assigned_employees: [input.assigned_employees],
      installation: [input.installation],
      type: [input.type]
    });

    this.jobsheetForm.get('assigned_employees').valueChanges.subscribe(value => {
      _this.assigned_employees = _this.users.filter(val => value.includes(val.id));
    });


    // this.jobsheetForm.get('installation').valueChanges.subscribe(value => {
    //   _this.installationService.get(value).subscribe(res => {
    //     _this.jobsheetForm.get('client').setValue(res['data']['client']['id']);
    //   },
    //     err => {
    //       console.log(err);
    //     })
    // });

    this.jobsheetForm.get('client').valueChanges.subscribe(value => {
      _this.loadInstallations(value);
    });

    this.jobsheetForm.valueChanges.subscribe(res => {
      if (_this.jobsheetForm.touched) {
        _this.isSaveRequired = true;
      }
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  isTouched(controlName) {
    if (this.jobsheetForm.get(controlName).touched && this.jobsheetForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  isItemTouched(form, controlName) {
    if (form.get(controlName).touched && form.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  activeStock: number;

  deleteSelectedItem() {
    this.stockItems.splice(this.activeStock, 1);
    this.deleteStock.hide();
  }

  setSelectedItem(index) {
    this.activeStock = index;
    console.log(this.activeStock);
    this.deleteStock.show();
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };
  loadJobsheet(id) {
    let _this = this;
    if (!_this.loading.isFirstLoad) {
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true;
    }
    this.jobsheetService.get(id).subscribe(res => {
      _this.jobsheet = new JobsheetModel(res['data']);
      _this.assigned_employees = res['data']['assigned_employees'];
      res['data']['status'] = res['data']['status'] ? res['data']['status']['id'] : null;
      res['data']['installation'] = res['data']['installation'] ? res['data']['installation']['id'] : null;
      res['data']['client'] = res['data']['client']['id'];
      res['data']['assigned_employees'] = res['data']['assigned_employees'] ? Array.from(res['data']['assigned_employees']).map(val => val['id']) : null;
      _this.loadInstallations(res['data']['client']);
      _this.stockItems = [];
      for (let item of res['data']['items']) {
        _this.newStockItem(item);
      }

      if (res['data']['actual_start_at']) {
        _this.fromDateActual = moment(res['data']['actual_start_at']).toDate();
      }
      if (res['data']['actual_finish_at']) {
        _this.toDateActual = moment(res['data']['actual_finish_at']).toDate();
      }

      if (res['data']['scheduled_start_at']) {
        _this.fromDate = moment(res['data']['scheduled_start_at']).toDate();
      }
      if (res['data']['scheduled_finish_at']) {
        _this.toDate = moment(res['data']['scheduled_finish_at']).toDate();
      }

      _this.createForm(res['data']);
      console.log(_this.jobsheet);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  loadingPDF = false;
  downloadPDF() {
    this.loadingPDF = true;
    this.jobsheetService.getPDF(Number(this.jobsheet.id), true).subscribe((res: any) => {
      FileSaver.saveAs('data:application/pdf;base64,' + res['data']['base_64'], `Jobsheet #${this.jobsheet.reference}.pdf`);
      this.loadingPDF = false;
    }, err => {
    }, () => {
    });
  }

  printPDF() {
    this.loadingPDF = true;
    this.jobsheetService.getPDF(Number(this.jobsheet.id), false).subscribe((res: any) => {
      window.open(res['data']['url']);
      this.loadingPDF = false;
    }, err => {
    }, () => {
    });
  }

  email: string = null;
  emailPDF() {
    let _this = this;
    this.isLoading = true;
    this.jobsheetService.mail(this.jobsheet.id, this.email).subscribe(res => {
      _this.sendpdf.hide();
      this.messageService.success("PDF Sent.", { Position: 'top', Style: 'bar', Duration: 5000 });
      console.log(res);
      _this.isLoading = false;
    },
      err => {
        this.messageService.error("Error Sending PDF.", { Position: 'top', Style: 'bar', Duration: 50000 });
        console.log(err);
        _this.isLoading = false;
      })
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.jobsheetService.delete(this.jobsheet.id).subscribe(res => {
      this.router.navigate(['/jobsheet']);
      this.messageService.remove();
      this.messageService.success("Jobsheet Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.jobsheetDelete.show();
  }

  @Input() external: boolean = false;
  @Input() isLoading: boolean = false;

  save() {
    console.log(this.jobsheetForm);
    this.jobsheetForm.updateValueAndValidity();
    if (this.jobsheetForm.invalid) {
      this.jobsheetForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if (this.isLoading) {
      return;
    }

    if (!this.stockItems || this.stockItems.length == 0) {
      this.messageService.error("A Jobsheet must have at least 1 Inventory Item selected.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }

    if (this.stockItems) {
      for (let item of this.stockItems) {
        if (item.invalid) {
          console.log(item);
          item.markAllAsTouched();
          this.messageService.remove();
          this.isLoading = false;
          this.messageService.error("Inventory Item Validation Error.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          return;
        }
      }
    }


    // let number = this.jobsheetForm.get('phone').value['number']
    // this.jobsheetForm.get('phone').setValue(number);
    this.isLoading = true;
    localStorage.removeItem("jobsheet-create");
    let _this = this;
    let rawValue = this.jobsheetForm.getRawValue();

    rawValue['scheduled_start_at'] = moment(this.fromDate).format();
    rawValue['scheduled_finish_at'] = moment(this.toDate).format();
    rawValue['items'] = [];

    if (_this.stockItems) {
      for (let item of _this.stockItems) {
        let itemRawValue = item.getRawValue();
        itemRawValue['unit_price'] = (+itemRawValue['unit_price'].toString().replace(/[^0-9\.]+/g, "")).toFixed(2);
        itemRawValue['vat_price'] = (+itemRawValue['vat_price'].toString().replace(/[^0-9\.]+/g, "")).toFixed(2);
        rawValue['items'].push(itemRawValue);
      }
    }

    if (this.fromDateActual) {
      rawValue['actual_start_at'] = moment(this.fromDateActual).format();
    }
    if (this.toDateActual) {
      rawValue['actual_finish_at'] = moment(this.toDateActual).format();
    }

    console.log(rawValue);

    this.jobsheetForm.markAsUntouched();
    this.jobsheetForm.updateValueAndValidity();
    if (this.jobsheet) {
      this.jobsheetService.edit(this.jobsheet.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Jobsheet Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        this.loadJobsheet(res['data']['id']);
        _this.isLoading = false;
        _this.didSave.emit(true);
        _this.isSaveRequired = false;
        _this.onSave.emit(res['data']);
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          _this.isLoading = false;
        });
    }
    else {
      this.jobsheetService.create(rawValue).subscribe(res => {
        this.router.navigate(['/jobsheet/' + res['data']['id']]);
        this.messageService.remove();
        this.messageService.success("Jobsheet Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
        _this.didSave.emit(true);
        _this.isSaveRequired = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        });
    }
  }

  fetchMoreUsers() {
    this.loadUsers();
  }

  loadUsers() {
    let _this = this;
    if (this.rearchedCapUser) {
      return true;
    }

    if (_this.timeoutUser) {
      clearTimeout(_this.timeoutUser);
    }

    if (_this.subscriptionUser) {
      _this.subscriptionUser.unsubscribe();
    }

    _this.timeoutUser = setTimeout(() => {

      _this.subscriptionUser = _this.userService.getAll({ per_page: 100, page: _this.lastPageUser }).subscribe(res => {
        if (_this.jobsheetForm.get('assigned_employees').value) {
          res['data'] = _this.Utils.filterArrayByIds(_this.jobsheetForm.get('assigned_employees').value, res['data']);
        }

        if (!_this.users || _this.users.length == 0) {
          let additions = _this.assigned_employees;
          res['data'] = [...additions, ...res['data']];
          _this.users = [...res['data']];
        }
        else {
          _this.users = [..._this.users, ...res['data']];
        }
        if (+_this.lastPageUser == +res['meta']['last_page']) {
          _this.rearchedCapUser = true;
        }
        for (let client of _this.users) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email;
          if (client.deleted_at) {
            client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email + ' - (Deleted)';
          }
        }
        _this.lastPageUser++;
      },
        err => {
          console.log(err);
        })

    }, 350);

  }

  users: UserModel[] = [];
  timeoutUser: any;
  lastPageUser = 1;
  rearchedCapUser: boolean = false;
  subscriptionUser: Subscription;
  onSearchUser(event) {
    let term = event;
    let _this = this;
    this.lastPageUser = 1;
    this.rearchedCapUser = false;
    this.users = [];
    if (term == null || term == '') {
      this.loadUsers();
      return;
    }
    if (_this.timeoutUser) {
      clearTimeout(_this.timeoutUser);
    }
    _this.timeoutUser = setTimeout(() => {
      if (_this.subscriptionUser) {
        _this.subscriptionUser.unsubscribe();
      }
      this.userService.getAll({ q: term, per_page: 100, page: this.lastPageUser }).subscribe(res => {

        res['data'] = _this.Utils.filterArrayByIds(_this.jobsheetForm.get('assigned_employees').value, res['data']);
        let additions = _this.assigned_employees;
        res['data'] = [...additions, ...res['data']];
        _this.users = res['data'];

        for (let client of _this.users) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email;
          if (client.deleted_at) {
            client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email + ' - (Deleted)';
          }
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  fetchMoreClients() {
    this.loadClients();
  }

  loadClients() {
    let _this = this;
    if (this.rearchedCapClient) {
      return true;
    }

    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }

    if (_this.subscription) {
      _this.subscription.unsubscribe();
    }

    _this.timeout = setTimeout(() => {

      _this.subscription = _this.clientService.getAll({ per_page: 100, page: _this.lastPageClient }).subscribe(res => {
        if (_this.clients && _this.clients.length == 0 && _this.jobsheet) {
          if (_this.jobsheet.client.deleted_at) {
            _this.clients.push(_this.jobsheet.client);
          }
        }
        if (!_this.clients) {
          _this.clients = [...res['data']];
        }
        else {
          _this.clients = [..._this.clients, ...res['data']];
        }
        if (+_this.lastPageClient == +res['meta']['last_page']) {
          _this.rearchedCapClient = true;
        }
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '');
          if (client.deleted_at) {
            client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '') + ' - (Deleted)';
          }
        }
        _this.lastPageClient++;
      },
        err => {
          console.log(err);
        })

    }, 350);

  }

  clients: ClientModel[] = [];
  timeout: any;
  lastPageClient = 1;
  rearchedCapClient: boolean = false;
  subscription: Subscription;
  onSearchClient(event) {
    let term = event;
    let _this = this;
    this.lastPageClient = 1;
    this.rearchedCapClient = false;
    this.clients = [];
    if (term == null || term == '') {
      this.loadClients();
      return;
    }
    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      if (_this.subscription) {
        _this.subscription.unsubscribe();
      }
      this.clientService.getAll({ q: term, per_page: 100, page: this.lastPageClient }).subscribe(res => {
        _this.clients = res['data'];
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '');
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }


  // installations: InstallationModel[] = [];
  // timeoutInstallation: any;
  // lastPageInstallation = 1;
  // rearchedCapInstallation: boolean = false;
  // subscriptionInstallation: Subscription;
  // onSearchInstallation(event) {
  //   let term = event;
  //   let _this = this;
  //   this.lastPageInstallation = 1;
  //   this.rearchedCapInstallation = false;
  //   this.installations = [];
  //   if (term == null || term == '') {
  //     this.loadClients();
  //     return;
  //   }
  //   if (_this.timeoutInstallation) {
  //     clearTimeout(_this.timeoutInstallation);
  //   }
  //   _this.timeoutInstallation = setTimeout(() => {
  //     if (_this.subscriptionInstallation) {
  //       _this.subscriptionInstallation.unsubscribe();
  //     }
  //     this.installationService.getAll({ q: term, per_page: 100, page: this.lastPageInstallation }).subscribe(res => {
  //       _this.installations = res['data'];
  //       for (let client of _this.installations) {
  //         client['search_name'] = client.reference + (client.address_1 ? ' - ' + client.address_1 : '');
  //         _this.installationsOfClient.set(client.id, false);
  //       }
  //     },
  //       err => {
  //         console.log(err);
  //       })

  //   }, 350);
  // }

  // fetchMoreInstallations() {
  //   this.loadInstallations();
  // }

  // loadInstallations() {
  //   let _this = this;
  //   if (this.rearchedCapInstallation) {
  //     return true;
  //   }

  //   if (_this.timeoutInstallation) {
  //     clearTimeout(_this.timeoutInstallation);
  //   }

  //   if (_this.subscriptionInstallation) {
  //     _this.subscriptionInstallation.unsubscribe();
  //   }

  //   _this.timeoutInstallation = setTimeout(() => {

  //     _this.subscriptionInstallation = _this.installationService.getAll({ per_page: 100, page: _this.lastPageInstallation }).subscribe(res => {
  //       if (_this.installations && _this.installations.length == 0 && _this.jobsheet) {
  //         if (_this.jobsheet.installation.deleted_at) {
  //           _this.installations.push(_this.jobsheet.installation);
  //         }
  //       }
  //       if (!_this.installations) {
  //         _this.installations = [...res['data']];
  //       }
  //       else {
  //         _this.installations = [..._this.installations, ...res['data']];
  //       }

  //       for (let installation of _this.installations) {
  //         _this.installationsOfClient.set(installation.id, false);
  //       }

  //       if (+_this.lastPageInstallation == +res['meta']['last_page']) {
  //         _this.rearchedCapInstallation = true;
  //       }
  //       for (let client of _this.installations) {
  //         client['search_name'] = client.reference + (client.address_1 ? ' - ' + client.address_1 : '');
  //         if (client.deleted_at) {
  //           client['search_name'] = client.reference + (client.address_1 ? ' - ' + client.address_1 : '') + ' - (Deleted)';
  //         }
  //       }
  //       _this.lastPageInstallation++;
  //     },
  //       err => {
  //         console.log(err);
  //       })

  //   }, 350);

  // }

  selectedFile: any;
  openDeleteFile(event) {
    this.selectedFile = event;
    this.deleteDocument.show();
  }

  deleteFile() {
    let _this = this;
    this.isLoading = true;
    this.jobsheetService.deleteFile(this.selectedFile.id).subscribe(res => {
      this.jobsheetService.get(_this.jobsheet.id).subscribe(resInstall => {
        _this.jobsheet.files = [...resInstall['data']['files']];
        _this.deleteDocument.hide();
      },
        err => {
          console.log(err);
        })
      _this.isLoading = false;
    },
      err => {
        _this.isLoading = false;
        console.log(err);
      })
  }

  savedEvent: any;
  fileData: {
    name: string,
    type: string
  }
  beginFileUpload(event) {
    let _this = this;
    this.savedEvent = event;
    console.log(event.target.files[0]);

    let fileSplitter = event.target.files[0].name.split(".");

    this.fileData = {
      name: fileSplitter[0],
      type: fileSplitter[1]
    };

    var reader = new FileReader();
    reader.onload = (event: any) => {
      _this.previewUpload = event.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  descriptionTouched(documentDescription) {
    if ($(documentDescription).hasClass('ng-touched') && $(documentDescription).hasClass('ng-invalid')) {
      return true;
    }
    return false;
  }

  docDesc: string;
  previewUpload: any[];
  uploadFile(documentDescription) {
    if (!this.previewUpload) {
      return;
    }
    // if (this.descriptionTouched(documentDescription) || !this.docDesc) {
    //   $(documentDescription).addClass('ng-touched');
    //   return;
    // }
    let _this = this;
    console.log(this.savedEvent);
    if (this.savedEvent.target.files && this.savedEvent.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        let data = {
          image: e.target.result,
          description: _this.docDesc,
          name: _this.fileData.name,
          type: _this.fileData.type
        };
        _this.isLoading = true;
        _this.jobsheetService.upload(_this.jobsheet.id, data).subscribe(res => {
          _this.messageService.success("File Uploaded.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          _this.jobsheet.files = _this.jobsheet.files ? [..._this.jobsheet.files, res['data']] : [res['data']];
          _this.uploadAttachment.hide();
          _this.docDesc = "";
          _this.previewUpload = null;
          _this.isLoading = false;
        },
          err => {
            _this.messageService.error("File Upload Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
            console.log(err);
            _this.isLoading = false;
          });
      }
      reader.readAsDataURL(this.savedEvent.target.files[0]);
    }
  }

  startJobsheet() {
    let _this = this;
    let currentDate = new Date();

    if (this.isLoading) {
      _this.messageService.success("You can't start the jobsheet while saving.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }

    this.jobsheetService.start(this.jobsheet.id, {}).subscribe(res => {
      _this.messageService.success("Jobsheet Started.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      let dateStarted = res['data']['actual_start_at'];
      _this.fromDateActual = new Date(dateStarted);
      _this.jobsheetForm.get('actual_start_at').setValue(new Date(dateStarted));
    },
      err => {
        console.log(err);
      })

    // let data = {
    //   actual_start_at: moment(currentDate).format()
    // };
    // this.jobsheetService.edit(this.jobsheet.id, data).subscribe(res => {
    //   _this.messageService.success("Jobsheet Started.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    //   _this.fromDateActual = currentDate;
    //   _this.jobsheetForm.get('actual_start_at').setValue(currentDate);
    // },
    //   err => {
    //     console.log(err);
    //   });
  }

  signer_id: string;
  signed_by: string;

  clearSignature() {
    this.signerForm.get('signature').setValue(null);
    this.signaturePad.clear();
  }

  signerForm: FormGroup;
  createSignerForm(input?) {
    this.signerForm = this.fb.group({
      signer_id: [input.signer_id],
      signed_by: [input.signed_by],
      signature: [input.signature, [Validators.required]]
    });
  }

  stopJobsheet() {
    let _this = this;
    // let data = {
    //   signature: this.latestSignature,
    //   signer_id: this.signer_id,
    //   signed_by: this.signed_by
    // };

    if (this.isLoading) {
      _this.messageService.success("You can't stop the jobsheet while saving.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }

    if (this.signerForm.invalid) {
      _this.messageService.error("Invalid Signer Details.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }

    let data = this.signerForm.getRawValue();
    this.isLoading = true;
    this.jobsheetService.finish(this.jobsheet.id, data).subscribe(res => {
      _this.messageService.success("Jobsheet Finished.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.loadJobsheet(_this.jobsheet.id);
      _this.signature.hide();
      _this.isLoading = false;
    },
      err => {
        console.log(err);
        _this.isLoading = false;
      });
  }

  didSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  stopJobsheetModal() {
    let _this = this;
    console.log(this.isSaveRequired);
    if (this.isSaveRequired) {
      let actions: ActionModel = {
        onAccept: {
          function: (event) => {
            _this.didSave = new EventEmitter<boolean>();
            _this.didSave.subscribe(res => {
              _this.signature.show();
              _this.isSaveRequired = false;
              _this.didSave.unsubscribe();
            });
            _this.save();
          },
          buttonText: "Yes"
        },
        onDecline: {
          function: (event) => {

          },
          buttonText: "No"
        }
      }
      this.messageService.sendNotification(`You have unsaved changes. Would you like to save them?`, 'intervention', actions, 0);
      //this.messageService.error("You have unsaved changes.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      return;
    }
    this.signature.show();
  }

  addClient(client: ClientModel) {
    this.clientModal.hide();
    this.jobsheetForm.get('client').setValue(client.id);
    this.lastPageClient = 1;
    this.rearchedCapClient = false;
    this.clients = [];
    this.loadClients();
  }

  addInstallation(installation: InstallationModel) {
    this.addInstallationModal.hide();
    this.jobsheetForm.get('installation').setValue(installation.id);
    if (this.jobsheetForm.get('client') && this.jobsheetForm.get('client').value) {
      this.loadInstallations(this.jobsheetForm.get('client').value);
    }
  }


  fetchMoreStock() {
    this.loadStock();
  }

  getFormattedStock(item: FormGroup) {
    if (item.get('id') && item.get('id').value && item.get('deleted_at') && item.get('deleted_at').value) {
      return this.stock.filter(val => val.deleted_at);
    }
    return this.stock.filter(val => !val.deleted_at);;
  }

  stock: StockModel[] = [];
  timeoutStock: any;
  lastPageStock = 1;
  rearchedCapStock: boolean = false;
  subscriptionStock: Subscription;
  unlistedItem: StockModel;
  loadStock() {
    let _this = this;
    if (this.rearchedCapStock) {
      return true;
    }

    if (_this.timeoutStock) {
      clearTimeout(_this.timeoutStock);
    }

    if (_this.subscriptionStock) {
      _this.subscriptionStock.unsubscribe();
    }

    _this.timeoutStock = setTimeout(() => {

      _this.subscriptionStock = _this.stockService.getAll({ per_page: 100, page: _this.lastPageStock }).subscribe(res => {
        _this.unlistedItem = res['meta']['unlisted_item'];
        if (!_this.stock) {
          _this.stock = [...res['data']];
        }
        else {
          if (_this.stock.length == 0) {
            _this.stock = [_this.unlistedItem]
            if (_this.jobsheet && _this.jobsheet.items) {
              for (let item of _this.jobsheet.items) {
                if (item.deleted_at) {
                  _this.stock.push(item);
                }
              }
            }
          }
          _this.stock = [..._this.stock, ...res['data']];
        }
        if (+_this.lastPageStock == +res['meta']['last_page']) {
          _this.rearchedCapStock = true;
        }
        for (let client of _this.stock) {
          client['search_name'] = client.reference;
          if (client.deleted_at) {
            client['search_name'] = client.reference + ' - (Deleted)';
          }
        }
        _this.lastPageStock++;
      },
        err => {
          console.log(err);
        })
    }, 350);
  }

  onSearchStock(event) {
    let term = event;
    let _this = this;
    this.lastPageStock = 1;
    this.rearchedCapStock = false;
    this.stock = [];
    if (term == null || term == '') {
      this.loadStock();
      return;
    }
    if (_this.timeoutStock) {
      clearTimeout(_this.timeoutStock);
    }
    _this.timeoutStock = setTimeout(() => {
      if (_this.subscriptionStock) {
        _this.subscriptionStock.unsubscribe();
      }
      this.stockService.getAll({ q: term, per_page: 100, page: this.lastPageStock }).subscribe(res => {
        _this.stock = res['data'];
        for (let client of _this.stock) {
          client['search_name'] = client.reference;
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  attachmentMenuItems: any[] = [
    { action: 'delete', message: 'Delete File', class: '', icon: 'fal fa-trash', permissions: ['update_jobsheet'] }
  ];

  activeDotMenus: Map<number, DotMenuComponent> = new Map<number, DotMenuComponent>();
  handleDotMenu(event, row, dotMenu) {
    this.activeDotMenus.forEach((value, key) => {
      if (value && value.state && key != row['id']) {
        value.state = false;
      }
    });
    this.activeDotMenus.set(row['id'], dotMenu);
  }

  attachmentButtonPress(event, row) {
    console.log(this.activeDotMenus);
    switch (event) {
      case "delete":
        this.openDeleteFile(row);
        break;
      default:
        console.log(event);
        break;
    }
  }


}
