import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_dashboard'
    },
    component: SimplyWhiteLayout,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/user/user.module#UserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'client',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_client'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/client/client.module#ClientModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'jobsheet',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/jobsheet/jobsheet.module#JobsheetModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'stock',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_stock'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/stock/stock.module#StockModule',
    runGuardsAndResolvers: 'always'
  },
  // {
  //   path: 'supplier',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //       breadcrumb: 'Supplier'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren: './view/supplier/supplier.module#SupplierModule',
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: 'invoice',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_invoice'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/invoice/invoice.module#InvoiceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'installation',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_installation'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/installation/installation.module#InstallationModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_dashboard'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'calendar',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/calendar/calendar.module#CalendarComponentModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'session',
    data: {
      permission:'view_dashboard'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'company',
    data: {
      permission:'view_company'
    },
    canActivate: [AuthGaurd,RoleGuard],
    component: SimplyWhiteLayout,
    loadChildren: "./view/company/company.module#CompanyModule",
    runGuardsAndResolvers: 'always'
  },
  // {
  //   path: 'casual',
  //   data: {
  //       breadcrumb: 'Home'
  //   },
  //   component: CasualLayout
  // },
  // {
  //   path: 'executive',
  //   data: {
  //       breadcrumb: 'Home'
  //   },
  //   component: ExecutiveLayout
  // },
  // {
  //   path: 'simplywhite',
  //   data: {
  //       breadcrumb: 'Home'
  //   },
  //   component: SimplyWhiteLayout
  // },
  // {
  //   path: 'corporate',
  //   data: {
  //       breadcrumb: 'Home'
  //   },
  //   component: CorporateLayout
  // },
];
