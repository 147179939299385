import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import { Page } from '../../../shared/models/page.model';
import { ModalDirective } from 'ngx-bootstrap';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { InstallationService } from '../../../shared/services/installation.service';
import { InstallationModel } from '../../../shared/models/installation.model';
import * as $ from 'jquery';
import { LocalityService } from '../../../shared/services/locality.service';
import { Subscription } from 'rxjs';
import { ClientModel } from '../../../shared/models/client.model';
import { ClientService } from '../../../shared/services/client.service';
import { AuthService } from '../../../shared/services/auth.service';
import * as moment from 'moment';
import { DotMenuComponent } from '../../components/dot-menu/dot-menu.component';

@Component({
  selector: 'app-installation-detail',
  templateUrl: './installation-detail.component.html',
  styleUrls: ['./installation-detail.component.scss']
})
export class InstallationDetailComponent implements OnInit {

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  installation: InstallationModel;
  installationForm: FormGroup;
  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  @ViewChild('mdSlideUp2', { static: true }) mdSlideUp2: ModalDirective;
  @ViewChild('mdSlideUp3', { static: true }) mdSlideUp3: ModalDirective;
  @ViewChild('mdSlideUp4', { static: true }) mdSlideUp4: ModalDirective;
  errors: String[] = [];
  ColumnMode = ColumnMode;
  localities = [];
  rows: any[];

  constructor(
    private cdr: ChangeDetectorRef,
    private installationService: InstallationService,
    private fb: FormBuilder,
    private router: Router,
    private snapshot: ActivatedRoute,
    private clientService: ClientService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private _location: Location,
    private localityService: LocalityService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadLocalities();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadInstallation(res['params']['id']);
      }
    })
  }

  menuItems: any[] = [
    { action: 'jobsheets', message: 'View Jobsheets', class: '', icon: 'fal fa-clipboard-list', permissions: ['view_jobsheet'] },
    { action: 'delete', message: 'Delete Installation', class: '', icon: 'fal fa-trash', permissions: ['delete_installation'] }
  ];

  handleButtonPress(event) {
    switch (event) {
      case "delete":
        this.mdSlideUp.show();
        break;
      case "jobsheets":
        this.router.navigate(['/jobsheet'], { queryParams: { insId: this.installation.id } })
        break;
      default:
        console.log(event);
        break;
    }
  }

  attachmentMenuItems: any[] = [
    { action: 'delete', message: 'Delete File', class: '', icon: 'fal fa-trash', permissions: ['update_installation'] }
  ];

  activeDotMenus: Map<number, DotMenuComponent> = new Map<number, DotMenuComponent>();
  handleDotMenu(event,row,dotMenu){
    this.activeDotMenus.forEach((value,key) => {
      if(value && value.state && key != row['id']){
        value.state = false;
      }
    });
    this.activeDotMenus.set(row['id'], dotMenu);
  }

  attachmentButtonPress(event, row) {
    console.log(this.activeDotMenus);
    switch (event) {
      case "delete":
        this.openDeleteFile(row);
        break;
      default:
        console.log(event);
        break;
    }
  }

  loadLocalities() {
    let _this = this;
    this.localities = this.localityService.getAllLocal();
    // this.localityService.getAll().subscribe(res => {
    //   _this.localities = res['data'];
    // },
    // err => {
    //   console.log(err);
    // });
  }

  @ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
  loadSavedData() {
    if (localStorage.getItem("installation-create")) {
      this.createForm(JSON.parse(localStorage.getItem("installation-create")));
      // if (this.installationForm.get('phone').value) {
      //   this.installationForm.get('phone').setValue(this.installationForm.get('phone').value['nationalNumber'].replace(" ", ""));
      // }
    }
    this.installationForm.markAllAsTouched();
    this.loadDataModal.hide();
  }

  deleteData() {
    localStorage.removeItem("installation-create");
    this.loadDataModal.hide();
  }

  sendBack() {
    this._location.back();
  }

  page: Page[] = [new Page(), new Page(), new Page()];
  currentPage: number[] = [1, 1, 1];
  searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

  markTouched() {
    this.installationForm.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  addClient(client: ClientModel) {
    this.mdSlideUp4.hide();
    this.installationForm.get('client').setValue(client.id);
    this.lastPageClient = 1;
    this.rearchedCapClient = false;
    this.clients = [];
    this.loadClients();
    console.log(client);
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      // if (!includes(allowed_types, fileInput.target.files[0].type)) {
      //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.cdr.detectChanges();
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  center = { lat: 35.895504489168204, lng: 14.440491743066445 };
  zoomLevel = 12;
  tilt = 20;
  map: any;
  allOptions = {
    zoomControl: false,
    draggable: true,
    panControl: true,
    scrollwheel: true,
    center: { lat: 35.895504489168204, lng: 14.440491743066445 },
    tilt: 20,
    zoom: 11,
    disableDefaultUI: true,
    disableDoubleClickZoom: true
  };

  marker = {
    lat: 35.895504489168204,
    lng: 14.440491743066445,
    pos: { lat: 35.895504489168204, lng: 14.440491743066445 },
    alpha: 1,
    //icon: { url: pickUp },
    draggable: true,
    location: "Test"
  };

  onPositionChange(event) {
    this.marker.lat = event.latLng.lat();
    this.marker.lng = event.latLng.lng();
    this.marker.pos = event.latLng;
    this.updateAddress(event.latLng.lat(), event.latLng.lng());
  }

  onInit(map) {
    // let m = new mapStyle();
    // this.styles = m.ecabMapStyle;
    this.map = map;
    //this.map.mapTypes.set('styled_map', new google.maps.StyledMapType(JSON.parse(JSON.stringify(m.ecabMapStyle))));
    //this.map.setMapTypeId('styled_map');
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  createForm(input?) {
    input = input || {};
    this.installationForm = this.fb.group({
      reference: [input.reference, Validators.required],
      description: [input.description, Validators.required],
      client: [input.client, Validators.required],
      installed_at: [input.installed_at, Validators.required],
      model: [input.model],
      files: [input.files],
      address_1: [input.address_1, Validators.required],
      address_2: [input.address_2],
      location: [input.location],
      post_code: [input.post_code],
      town: [input.town],
      country: [input.country]
    });
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  onMapClick(event) {
    this.onPositionChange(event);
    this.updateAddress(event.latLng.lat(), event.latLng.lng());
    this.focus(event.latLng);
  }

  focus(latLng) {
    this.map.panTo(latLng);
  }

  updateAddress(lat, lng) {
    let _this = this;
    const latlng = {
      lat: lat,
      lng: lng,
    };

    _this.installationForm.get('location').setValue(latlng);
    _this.installationService.getLatLngDetails(lat, lng).subscribe(res => {
      let addressFields = res['address'];
      _this.installationForm.get('address_1').setValue(addressFields['road'] ? addressFields['road'] : addressFields['suburb'] ? addressFields['suburb'] : addressFields['retail']);
      _this.installationForm.get('address_2').setValue(addressFields['state']);
      _this.installationForm.get('town').setValue(addressFields['town'] ? addressFields['town'] : addressFields['village']);
      _this.installationForm.get('country').setValue(addressFields['country']);
      _this.installationForm.get('post_code').setValue(addressFields['postcode']);
    },
      err => {
        console.log(err);
      })
  }

  newClient() {

  }

  openLink(link) {
    window.open(link);
  }

  savedEvent: any;
  fileData: {
    name: string,
    type: string
  }
  beginFileUpload(event) {
    let _this = this;
    this.savedEvent = event;
    console.log(event.target.files[0]);

    let fileSplitter = [...event.target.files[0].name.split(".")];

    this.fileData = {
      name: fileSplitter[0],
      type: fileSplitter[1]
    };

    var reader = new FileReader();
    reader.onload = (event: any) => {
      _this.previewUpload = event.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  docDesc: string;
  previewUpload: any[];

  descriptionTouched(documentDescription) {
    if ($(documentDescription).hasClass('ng-touched') && $(documentDescription).hasClass('ng-invalid')) {
      return true;
    }
    return false;
  }

  uploadFile() {
    if (!this.previewUpload) {
      return;
    }
    // if(this.descriptionTouched(documentDescription) || !this.docDesc){
    //   $(documentDescription).addClass('ng-touched');
    //   return;
    // }
    let _this = this;
    this.isLoading = true;
    console.log(this.savedEvent);
    if (this.savedEvent.target.files && this.savedEvent.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        let data = {
          image: e.target.result,
          description: _this.docDesc,
          name: _this.fileData.name,
          type: _this.fileData.type
        };
        _this.installationService.upload(_this.installation.id, data).subscribe(res => {
          _this.messageService.success("File Uploaded.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          _this.installation.files = _this.installation.files ? [..._this.installation.files, res['data']] : [res['data']];
          _this.mdSlideUp2.hide();
          _this.docDesc = "";
          _this.previewUpload = null;
          _this.isLoading = false;
        },
          err => {
            _this.messageService.error("File Upload Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
            console.log(err);
            _this.isLoading = false;
          });
      }
      reader.readAsDataURL(this.savedEvent.target.files[0]);
    }
  }
  // beginFileUpload(event){
  //   let _this = this;

  //   this.savedEvent = event;
  //   console.log(event.target.files[0]);

  //   this.mdSlideUp2.show();
  //   this.mdSlideUp2.onHide.subscribe(data => {
  //     _this.activeFile = null;
  //   });
  // }

  // docDesc:string;
  // previewUpload:any[];
  // uploadFile() {
  //   let _this = this;
  //   console.log(this.savedEvent);
  //   if (this.savedEvent.target.files && this.savedEvent.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = function (e: any) {
  //       let data = {
  //         image:e.target.result,
  //         description:_this.docDesc
  //       };
  //       _this.installationService.upload(_this.installation.id,data).subscribe(res => {
  //         _this.messageService.success("File Uploaded.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
  //         _this.installation.files = _this.installation.files ? [..._this.installation.files,res['data']] : [res['data']];
  //         _this.mdSlideUp2.hide();
  //         _this.docDesc = "";
  //         _this.previewUpload = null;
  //       },
  //       err => {
  //         _this.messageService.error("File Upload Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
  //         console.log(err);
  //       });
  //     }
  //     reader.readAsDataURL(this.savedEvent.target.files[0]);
  //   }
  // }

  isTouched(controlName) {
    if (this.installationForm.get(controlName).touched && this.installationForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadInstallation(id) {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.installationService.get(id).subscribe(res => {
      //res['data']['client'] = res['data']['client']['id'];
      _this.installation = res['data'];
      _this.loadClients();
      _this.marker.pos = { lat: +res['data']['location']['lat'], lng: +res['data']['location']['lng'] };
      _this.marker.lat = +res['data']['location']['lat'];
      _this.marker.lng = +res['data']['location']['lng'];
      let formattedInstallation = {
        ...res['data'],
        client: res['data']['client']['id']
      };
      console.log(formattedInstallation);
      _this.createForm(formattedInstallation);
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  delete() {
    let _this = this;
    this.isLoading = true;
    this.installationService.delete(this.installation.id).subscribe(res => {
      this.router.navigate(['/installation']);
      this.messageService.remove();
      this.messageService.success("Installation Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      _this.isLoading = false;
    },
      err => {
        this.messageService.remove();
        this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(err);
        _this.isLoading = false;
      });
  }

  showSlideUp() {
    this.mdSlideUp.show();
  }

  @Input() isLoading: boolean = false;
  save() {
    this.installationForm.updateValueAndValidity();
    if (this.installationForm.invalid) {
      this.installationForm.markAllAsTouched();
      this.isLoading = false;
      return;
    }

    if (this.isLoading) {
      return;
    }

    localStorage.removeItem("installation-create");
    let _this = this;
    _this.isLoading = true;
    let rawValue = this.installationForm.getRawValue();
    rawValue['installed_at'] = moment(rawValue['installed_at']).format();
    if (rawValue['client']['id']) {
      rawValue['client'] = rawValue['client']['id'];
    }
    this.installationForm.markAsUntouched();
    this.installationForm.updateValueAndValidity();
    if (this.installation) {
      this.installationService.edit(this.installation.id, rawValue).subscribe(res => {
        this.messageService.remove();
        this.messageService.success("Installation Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        console.log(res['data']);
        _this.createForm(res['data']);
        console.log(_this.installationForm);
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          this.messageService.remove();
          this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          console.log(err);
          _this.isLoading = false;
        });
    }
    else {
      this.installationService.create(rawValue).subscribe(res => {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/installation/' + res['data']['id']]);
        }
        this.messageService.remove();
        this.messageService.success("Installation Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        _this.isLoading = false;
      },
        err => {
          _this.errors = _this.errorHandler.getErrors(err);
          console.log(err);
          _this.isLoading = false;
        });
    }
  }

  fetchMoreClients() {
    this.loadClients();
  }

  loadClients() {
    let _this = this;
    if (this.rearchedCapClient) {
      return true;
    }

    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }

    if (_this.subscription) {
      _this.subscription.unsubscribe();
    }

    _this.timeout = setTimeout(() => {

      _this.subscription = _this.clientService.getAll({ per_page: 100, page: _this.lastPageClient }).subscribe(res => {
        if(_this.clients && _this.clients.length == 0 && _this.installation){
          if(_this.installation.client.deleted_at){
            _this.clients.push(_this.installation.client);
          }
        }
        if (!_this.clients) {
          _this.clients = [...res['data']];
        }
        else {
          _this.clients = [..._this.clients, ...res['data']];
        }
        if (+_this.lastPageClient == +res['meta']['last_page']) {
          _this.rearchedCapClient = true;
        }
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '');
          if(client.deleted_at){
            client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '')+' - (Deleted)';
          }
        }
        _this.lastPageClient++;
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  selectedFile: any;
  openDeleteFile(event) {
    this.selectedFile = event;
    this.mdSlideUp3.show();
  }

  deleteFile() {
    let _this = this;
    this.isLoading = true;
    this.installationService.deleteFile(this.selectedFile.id).subscribe(res => {
      this.installationService.get(_this.installation.id).subscribe(resInstall => {
        _this.installation.files = [...resInstall['data']['files']];
        _this.mdSlideUp3.hide();
      },
        err => {
          console.log(err);
        })
        _this.isLoading = false;
    },
      err => {
        console.log(err);
        _this.isLoading = false;
      })
  }

  clients: ClientModel[] = [];
  timeout: any;
  lastPageClient = 1;
  rearchedCapClient: boolean = false;
  subscription: Subscription;
  onSearchClient(event) {
    let term = event;
    let _this = this;
    this.lastPageClient = 1;
    this.rearchedCapClient = false;
    this.clients = [];
    if (term == null || term == '') {
      this.loadClients();
      return;
    }
    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      if (_this.subscription) {
        _this.subscription.unsubscribe();
      }
      this.clientService.getAll({ q: term, per_page: 100, page: this.lastPageClient }).subscribe(res => {
        _this.clients = res['data'];
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.address_1;
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }


}
