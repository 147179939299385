import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { SubscriptionService } from '../../../shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { PaymentComponent } from '../payment/payment.component';

@Component({
  selector: 'app-card-modal',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class CardModalComponent implements OnInit {

  @ViewChild('paymentComponent', { static: false }) paymentComponent: PaymentComponent;
  @ViewChild('newCard', { static: true }) newCard: ModalDirective;
  @Input() showModal: EventEmitter<any>;
  @Input() setDefaultCard : boolean = false;
  @Input() isLoading:boolean = false;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private subService : SubscriptionService
  ) {

  }
  //4242 4242 4242 4242
  hideCardModal() {
    this.newCard.hide();
    //console.log("Card Added!");
    this.subService.cardsUpdated.emit(true);
  }

  addCreditCard() {
    let _this = this;
    this.paymentComponent.addNewCard();
  }

  openNewCard() {
    this.newCard.show();
    this.paymentComponent.paymentFailed = false;
    this.paymentComponent.paymentForm.reset();
    this.paymentComponent.cardNumber.element.clear();
    this.paymentComponent.cvc.element.clear();
    this.paymentComponent.expiry.element.clear();
  }

  ngOnInit() {
    let _this = this;
    this.showModal.subscribe(res => {
      _this.openNewCard();
    },
      err => {
        console.log(err);
      });
  }

  isSystemAdmin(): boolean {
    if (this.authService.getUserRole() == 1) {
      return true;
    }
    return false;
  }



}
