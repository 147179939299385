import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { StripeService, StripeCardNumberComponent, StripeCardComponent, StripeCardExpiryComponent, StripeCardCvcComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';
import { Utils } from '../../../shared/services/utils.service';
import { SubscriptionService } from '../../../shared/services/subscription.service';
import { SubscriptionModel } from '../../../shared/models/subscription.model';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CardModelTemplate } from '../../../shared/models/card.model';
import { Subscription } from 'rxjs';
import { LocalStoreService } from '../../../shared/services/local-store.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  //Stripe Things


  //Get Client secret to also send payment intent id.
  //Payment Intent Check which sends the payment intent id.

  @Input() setDefaultCard : boolean = false;
  @Input() slots: number;
  @Output() paymentSuccess: EventEmitter<any> = new EventEmitter<any>();
  automaticPaymentFailed: boolean = false;
  @Input() forceShow: boolean = false;
  stripeCardValid: boolean = false;
  paymentFailed: boolean = false;
  @ViewChild(StripeCardNumberComponent, { static: false }) cardNumber: StripeCardNumberComponent;
  @ViewChild(StripeCardExpiryComponent, { static: false }) expiry: StripeCardExpiryComponent;
  @ViewChild(StripeCardCvcComponent, { static: false }) cvc: StripeCardCvcComponent;
  @Output() isLoading:EventEmitter<boolean> = new EventEmitter<boolean>();

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  paymentForm: FormGroup;
  paymentRequestOptions = {
    country: 'MT',
    currency: 'eur',
    total: {
      label: 'Demo Total',
      amount: 1099,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private stripeService: StripeService,
    private utils: Utils,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private store : LocalStoreService
  ) { }

  initTimeout: any;
  cardValidation: any = {
    number: {
      touched: false,
      valid: false
    },
    expiry: {
      touched: false,
      valid: false
    },
    cvc: {
      touched: false,
      valid: false
    }
  };

  ngOnInit() {
    let _this = this;
    this.createForm({});
    let interval = setInterval(() => {
      if (_this.cardNumber) {
        _this.cardNumber.change.subscribe(change => {
          _this.cardValidation.number.touched = true;
          _this.cardValidation.number.valid = change.complete;
          console.log(_this.cardValidation.number);
        })
      }
      if (_this.expiry) {
        _this.expiry.change.subscribe(change => {
          _this.cardValidation.expiry.touched = true;
          _this.cardValidation.expiry.valid = change.complete;
        })
      }
      if (_this.cvc) {
        _this.cvc.change.subscribe(change => {
          _this.cardValidation.cvc.touched = true;
          _this.cardValidation.cvc.valid = change.complete;
        })
        clearInterval(interval);
      }
    }, 1000);
    // setInterval(() => {
    //   console.log(_this.isLoading);
    // }, 500);
  }

  createForm(input?) {
    input = input || {};
    this.paymentForm = this.fb.group({
      full_name: [input.full_name, Validators.required]
    });
  }



  confirmPayment() {
    //this.subscriptionConfirm.show();
  }

  formatNumber(value) {
    return this.utils.formatNumbersToFixedWithCommas(value);
  }

  isTouched(controlName, form?) {
    if (form) {
      if (form.get(controlName).touched && form.get(controlName).invalid) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  startManualPayment() {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      this.cardValidation.number.touched = true;
      this.cardValidation.expiry.touched = true;
      this.cardValidation.cvc.touched = true;
      return;
    }
    const name = this.paymentForm.get('full_name').value;
    this.cardValidation.number.touched = true;
    this.cardValidation.expiry.touched = true;
    this.cardValidation.cvc.touched = true;
    let _this = this;
    this.isLoading.emit(true);
    this.paySub = this.subscriptionService.startPaymentIntent({ slots: this.slots }).subscribe(res => {
      console.log(res);
      _this.pay(res['data']['client_secret'], res['data']['intent_id']);
    },
      err => {
        console.log(err);
        _this.paySub = null;
        _this.isLoading.emit(false);
      })
  }

  paySub: Subscription;
  pay(client_secret, intent_id): void {
    let _this = this;
    let cardPaymentData = {
      payment_method: {
        card: _this.cardNumber.element,
        billing_details: {
          name: _this.paymentForm.get('full_name').value,
        },
      },
    };
    this.paySub = this.stripeService.confirmCardPayment(client_secret, cardPaymentData).subscribe(resPayment => {
      this.stripeService.createToken(_this.cardNumber.element).subscribe(resToken => {
        let verifyData = {
          intent_id: intent_id,
          ...resToken['token']
        };
        _this.subscriptionService.verifyPayment(verifyData).subscribe(resVerify => {
          _this.paymentSuccess.emit(true);
          _this.paymentForm.reset();
          _this.cardNumber.element.clear();
          _this.expiry.element.clear();
          _this.cvc.element.clear();
          _this.isLoading.emit(false);
        },
          err => {
            console.log(err);
            _this.paymentFailed = true;
            _this.paySub = null;
            _this.isLoading.emit(false);
          });
      },
        err => {
          console.log(err);
          _this.paySub = null;
          _this.isLoading.emit(false);
        });
    },
      err => {
        console.log(err);
        _this.paySub = null;
        _this.isLoading.emit(false);
      });
  }

  addSub: Subscription;
  addNewCard() {
    let _this = this;
    _this.isLoading.emit(true);
    this.addSub = this.stripeService.createToken(_this.cardNumber.element).subscribe(resToken => {
      if(_this.setDefaultCard){
        resToken.token['is_active'] = true;
      }
      _this.subscriptionService.create(resToken['token']).subscribe(resVerify => {
        _this.paymentSuccess.emit(true);
        _this.messageService.success("Card Added Successfully.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        let updatedUser = _this.store.getItem('currentUser');
        updatedUser['company']['renewal'] = null;
        _this.store.setItem('currentUser',updatedUser);
        _this.addSub = null;
        _this.isLoading.emit(false);
      },
        err => {
          console.log(err);
          _this.paymentFailed = true;
          _this.addSub = null;
          _this.messageService.error("Card could not be added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
          _this.isLoading.emit(false);
        });
    },
      err => {
        console.log(err);
      });
  }

  tryAutomaticPayment() {
    let _this = this;
    _this.isLoading.emit(true);
    this.addSub = this.subscriptionService.tryAutomaticPayment({ slots: _this.slots }).subscribe(res => {
      console.log(res);
      _this.paymentSuccess.emit(true);
      _this.isLoading.emit(false);
    },
      err => {
        console.log(err);
        _this.automaticPaymentFailed = true;
        _this.addSub = null;
        _this.isLoading.emit(false);
      });
  }



}
