import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  //public didFirstLoad:boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  filterArrayByIds(idList: number[], array: any[]) {
    let filteredArray = array.filter(item => !idList.includes(item.id));
    let mapValues: Map<number, any> = new Map<number, any>();
    for (let val of filteredArray) {
      mapValues.set(val.id, val);
    }
    return Array.from(mapValues.values());
  }

  formatNumbersToFixedWithCommas(number){
    var amount=parseFloat(number).toFixed(2);
    var formattedString= amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  }

  exportTableDownload(table, params) {
    this.http.post(`${environment.baseUrl}/` + table + `/export`, params).subscribe(res => {
      let f = res['data'];
      let csv = res['meta']['columns'].join(",") + "\n";
      for (let row in f) {
        var rowArr = [];
        for (let key in f[row]) {
          rowArr.push(f[row][key]);
        }
        csv += rowArr.join(",") + '\n';
      }
      console.log(csv);
      const blob = new Blob([csv], { type: "text/plain" });
      console.log(f);
      FileSaver.saveAs(blob, table + ".csv");
    },
      err => {
        console.log(err);
      })
  }

  submitToggle(activeActionTitle,messageService,searchParams,rows,tableName) {
    let _this = this;
    switch (activeActionTitle) {
      case 'Export Selected':
        messageService.remove();
        messageService.success("Exported Selected Records!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        let recordIds: number[] = [];
        console.log(rows);
        for (let row of rows) {
          recordIds.push(row.id);
        }
        let params = { ...searchParams, list_all: false, records: recordIds }
        this.exportTableDownload(tableName, params);
        break;
      case 'Export All':
        messageService.remove();
        this.exportTableDownload(tableName, { ...searchParams, list_all: true, records: [] });
        messageService.success("Exported All Records!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        break;
      default:
        messageService.error("Please select a bulk action!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        break;
    }
  }



}
