import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import * as $ from 'jquery';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../@pages/components/message/message.service';
import { InstallationModel } from '../../../shared/models/installation.model';
import { Page } from '../../../shared/models/page.model';
import { AuthService } from '../../../shared/services/auth.service';
import { InstallationService } from '../../../shared/services/installation.service';
import { Utils } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-installation-list',
  templateUrl: './installation-list.component.html',
  styleUrls: ['./installation-list.component.scss']
})
export class InstallationListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  freeSearch: string;
  installations: InstallationModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];
  
  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private installationService: InstallationService,
    private authService: AuthService,
    private messageService : MessageService,
    private utils : Utils
  ) {
    
  }

  hasPermission(permission){
    return this.authService.hasPermission(permission);
  }

  ngOnInit() {
    this.loadFilters();
    this.loadInstallations();
    this.columns = [
      { name: 'Reference', flexGrow: 1, sortable: true, cellClass: 'installation_name', headerClass: 'installation_name' },
      { name: 'Client', flexGrow: 1, sortable: true, cellClass: 'installation_email', headerClass: 'installation_email' },
      { name: 'Description', flexGrow: 2, sortable: true, cellClass: 'installation_role', headerClass: 'installation_role' }
    ];
    this.updateMenuItems();
  }

  isSystemAdmin(): boolean {
    if (this.authService.getUserRole() == 1) {
      return true;
    }
    return false;
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadInstallations();
  }


  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadInstallations() {
    let _this = this;
    _this.installations = [];
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    this.installationService.getAll(this.searchParams).subscribe(res => {
      _this.page = res['meta'];
      _this.installations = res['data'];
      _this.rows = [];
      //console.log(res['data']['data'])
      for (let installation of _this.installations) {
        _this.rows.push(
          {
            id: installation.id,
            reference: installation.reference,
            client: installation.client,
            description: installation.description
          }
        );
      }
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    //console.log(pageInfo);
    this.searchParams.page = this.currentPage;
    //console.log(this.currentPage);
    this.loadInstallations();
    this.setFilter("pageInstallation",""+this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      this.router.navigate(['/installation/' + id]);
    }
  }

  clearFilters() {
    this.freeSearch = "";
    this.updateFilters();
  }

  setFilter(name, value) {
    if (value) {
      localStorage.setItem(name, JSON.stringify(value));
    }
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      localStorage.removeItem("searchInstallation");
      _this.setFilter("pageInstallation", _this.currentPage);
      _this.setFilter("searchInstallation", _this.freeSearch);
    }, 250);
  }

  getFilter(name): any {
    if (localStorage.getItem(name)) {
      let val = JSON.parse(localStorage.getItem(name));
      
      return val;
    }
    return null;
  }

  loadFilters() {
    for (let i = 0; i < localStorage.length; i++) {
      
      if (localStorage.getItem(localStorage.key(i)) == "undefined" || localStorage.getItem(localStorage.key(i)) == undefined || localStorage.getItem(localStorage.key(i)) == null) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    this.freeSearch = this.getFilter("searchInstallation");
    this.currentPage = (this.getFilter("pageInstallation") ? this.getFilter("pageInstallation") : 1);
    this.updateFilters();
  }

  filterLoadTimeout:any
  updateFilters(skip?) {
    let _this = this;
		_this.searchParams = { per_page: environment.per_page, page: 1 };
		if (!skip) {
		_this.searchParams.page = _this.currentPage;
		}
		else {
		_this.currentPage = 1;
		_this.setFilter("pageInstallation", 1);
		}
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }
    if (this.filterLoadTimeout) {
			clearTimeout(this.filterLoadTimeout);
		}
		this.filterLoadTimeout = setTimeout(() => {
			this.loadInstallations();
		}, 350);
    this.saveFilters();
  }

  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;
  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_jobsheet'] },
    { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_jobsheet'] },
  ];

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'installation');
    this.activeRows = new Map<number,any>();
  }

  activeRows:Map<number,any> = new Map<number,any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if(row['bulk_checked']){
      _this.activeRows.set(row['id'],row);
    }
    else{
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  updateMenuItems(){
    for(let item of this.menuItems){
      if(this.activeRows.size == 0){
        if(item.action.includes('export-selected')){
          item.Disabled = true;
        }
      }
      else{
        if(item.action.includes('export-selected')){
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if(this.mainToggle == true){
        _this.activeRows.set(item.id,item);
      }
      else{
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

}
