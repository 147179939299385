import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotificationModel } from '../models/notification.model';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';
import { Responses } from '../../../constants';
import { Page } from '../models/page.model';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private messageService: MessageService
    ) { }

    onUpdate:EventEmitter<boolean> = new EventEmitter<boolean>();

    getAll(searchParams) {
        return this.http.get(`${environment.baseUrl}/notification`, { params: searchParams });
    }

    getUserAll(searchParams) {
        return this.http.get(`${environment.baseUrl}/notification`, { params: searchParams });
    }

    get(id) {
        return this.http.get(`${environment.baseUrl}/notification/` + id);
    }

    edit(id, formData) {
        return this.http.put(`${environment.baseUrl}/notification/` + id, formData);
    }

    create(formData) {
        return this.http.post(`${environment.baseUrl}/notification`, formData);
    }

    delete(id) {
        return this.http.delete(`${environment.baseUrl}/notification/` + id);
    }

    read(id) {
        return this.http.get(`${environment.baseUrl}/notification/` + id + `/read`);
    }

    readAll() {
        return this.http.get(`${environment.baseUrl}/notification/read`);
    }

    getTypes() {
        return this.http.get(`${environment.baseUrl}/notification/type`);
    }

    getUserLinkedTypes(userId) {
        return this.http.get(`${environment.baseUrl}/user/${userId}/notification`);
    }

    linkUserToType(userId, typeId) {
        return this.http.post(`${environment.baseUrl}/user/${userId}/notification/${typeId}`, {});
    }

    unlinkUserFromType(userId, typeId) {
        return this.http.delete(`${environment.baseUrl}/user/${userId}/notification/${typeId}`, {});
    }

    linkToJobsheet(jobsheetId) {
        return this.http.post(`${environment.baseUrl}/jobsheet/${jobsheetId}/notification`, {});
    }

    linkToInvoice(invoiceId) {
        return this.http.post(`${environment.baseUrl}/invoice/${invoiceId}/notification`, {});
    }

    linkToStock(stockId) {
        return this.http.post(`${environment.baseUrl}/stock/${stockId}/notification`, {});
    }

    activateNotification(notification) {
        this.markRead(notification);
        switch (notification.description.split(" ")[0].toLowerCase()) {
            case "stock":
                this.router.navigate(['/stock/' + notification.properties.stock]);
                break;
            case "invoice":
                this.router.navigate(['/invoice/' + notification.properties.invoice]);
                break;
            case "jobsheet":
                this.router.navigate(['/jobsheet/' + notification.properties.jobsheet]);
                break;
        }
    }


    notifications: NotificationModel[] = [];
    has_unread: number = 0;
    page: Page;
    notificationSub: Subscription;
    loadNotifications(isLoadMore) {
        let _this = this;
        if (this.page && this.page.current_page == this.page.last_page && isLoadMore) {
            return;
        }
        if (!this.page || !isLoadMore) {
            this.page = new Page();
            this.page.current_page = 0;
        }
        let params = { per_page: 25, page: this.page.current_page + 1 };
        console.log("Reloading Notifications!");
        this.notificationSub = this.getUserAll(params).subscribe(res => {
            if(isLoadMore){
                _this.notifications = [..._this.notifications, ...res['data']];
            }
            else{
                 _this.notifications = res['data'];
            }
            _this.has_unread = res['has_unread'];
            _this.page = res['meta'];
            _this.page.current_page = res['meta']['current_page'];
            console.log("Reloaded Notifications!");
            setTimeout(() => {
                _this.notificationSub = null;
            }, 500);
        },
            err => {
                console.log(err);
                setTimeout(() => {
                    _this.notificationSub = null;
                }, 500);
            });
    }

    readAllNotifications() {
        let _this = this;
        for(let notif of _this.notifications){
            if(!notif.read_at){
                notif.read_at = moment(new Date()).format();
            }
        }
        this.readAll().subscribe(res => {
            _this.messageService.success("All notifications have been marked as read.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        },
            err => {
                console.log(err);
            })
    }

    markRead(notification: NotificationModel) {
        let _this = this;
        if (notification.read_at) {
            return;
        }
        this.read(notification.id).subscribe(res => {
            notification.read_at = moment(new Date()).format();
            _this.messageService.success("Notification has been marked as read.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        },
            err => {
                console.log(err);
            })
    }

    getNotificationClasses(notification: NotificationModel) {
        let classes: string[] = [];
        classes.push(notification.notification_type);
        return classes;
        //{'unread':!notification.read_at,notification.type};
    }

    hasUnreadNotification() {
        return this.has_unread > 0;
    }


    handleNotification(type, actions) {
        const submitFunction = (type) => {
            switch (type) {
                case Responses.accept:
                    console.log("Accepted Notification.");
                    break;
                case Responses.decline:
                    console.log("Declined Notification.");
                    break;
                case Responses.ignore:
                    console.log("Ignored Notification.");
                    break;
            }
        }
        this.messageService.sendNotification("Test Message", type, actions);
    }


}
