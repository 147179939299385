import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { JobsheetModel } from '../../../shared/models/jobsheet.model';
import { JobsheetService } from '../../../shared/services/jobsheet.service';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ClientService } from '../../../shared/services/client.service';
import { UserService } from '../../../shared/services/user.service';
import { InstallationService } from '../../../shared/services/installation.service';
import { UserModel } from '../../../shared/models/user.model';
import { Subscription } from 'rxjs';
import { ClientModel } from '../../../shared/models/client.model';
import { InstallationModel } from '../../../shared/models/installation.model';
import * as moment from 'moment';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import { Utils } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-jobsheet-list',
  templateUrl: './jobsheet-list.component.html',
  styleUrls: ['./jobsheet-list.component.scss']
})
export class JobsheetListComponent implements OnInit {


  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  freeSearch: string;
  jobsheets: JobsheetModel[];
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];

  fromDate:Date = null;
  toDate:Date = null;
  status:number[];
  installation:number[];
  client:number[];
  employee:number[];


  searchParams = { per_page: environment.per_page, page: this.currentPage };

  constructor(
    private router: Router,
    private jobsheetService: JobsheetService,
    private authService: AuthService,
    private messageService: MessageService,
    private snapshot: ActivatedRoute,
    private clientService: ClientService,
    private userService: UserService,
    private installationService: InstallationService,
    private cdr : ChangeDetectorRef,
    private utils : Utils
  ) {

  }

  ngOnInit() {
    let _this = this;
    this.snapshot.queryParamMap.subscribe(res => {
      if (res['params']['cId']) {
        _this.client = [+res['params']['cId']];
        _this.showFilters = true;
      }
      else if (res['params']['insId']) {
        _this.installation = [+res['params']['insId']];
        _this.showFilters = true;
      }
    })
    this.columns = [
      { name: 'Reference', flexGrow: 1, sortable: true, cellClass: 'jobsheet_ref', headerClass: 'jobsheet_ref' },
      { name: 'Status', flexGrow: 1, sortable: true, cellClass: 'jobsheet_status', headerClass: 'jobsheet_status' },
      { name: 'Description', flexGrow: 3, sortable: true, cellClass: 'jobsheet_desc', headerClass: 'jobsheet_desc' }
    ];
    this.loadFilters();
    this.loadJobsheets();
    this.loadStatuses();
    this.loadClients();
    this.loadInstallations();
    this.loadUsers();
    this.updateMenuItems();
  }

  showFilters:boolean = false;

  toggleFilters(){
    this.showFilters = !this.showFilters;
  }

  onSort(event) {
    console.log(event);
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadJobsheets();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  loading = {
    isFirstLoad:false,
    isLoading:false
  };
  loadJobsheets() {
    let _this = this;
    if(!_this.loading.isFirstLoad){
      _this.loading.isFirstLoad = true;
      _this.loading.isLoading = true; 
    }
    _this.jobsheets = [];
    this.jobsheetService.getAll(this.searchParams).subscribe(res => {
      _this.page = res['meta'];
      _this.jobsheets = res['data'];
      _this.rows = [];
      for (let jobsheet of _this.jobsheets) {
        _this.rows.push(
          {
            id: jobsheet.id,
            reference: jobsheet.reference,
            status: jobsheet.status ? jobsheet.status.title : '',
            description: jobsheet.description,
            created_at: jobsheet['created_at'],
            assigned_employees: jobsheet.assigned_employees,
            installation: jobsheet.installation ? jobsheet.installation.reference : 'N/A',
            actual_start_at: jobsheet.actual_start_at,
            actual_finish_at: jobsheet.actual_finish_at,
          }
        );
      }
      _this.loading.isLoading = false;
    },
    err => {
      _this.loading.isLoading = false;
      console.log(err);
    });
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.searchParams.page = this.currentPage;
    this.loadJobsheets();
    this.setFilter("pageJobsheet", "" + this.currentPage);
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  onFocus(event) {
    if (event['type'] == 'click') {
      let id = event['row']['id'];
      this.router.navigate(['/jobsheet/' + id]);
    }
  }

  clearFilters() {
    this.freeSearch = "";
    this.employee = null;
    this.status = null;
    this.client = null;
    this.installation = null;
    this.fromDate = null;
    this.toDate = null;
    this.updateFilters();
  }

  setDates(){
    console.log("Setting Dates");
    if(!this.fromDate){
      this.fromDate = moment(new Date()).startOf('d').toDate();
    }
    if(!this.toDate){
      this.toDate = moment(new Date()).endOf('d').toDate(); 
    }
  }

  setFilter(name, value) {
    if (value) {
      localStorage.setItem(name, JSON.stringify(value));
    }
  }

  saveFilters() {
    let _this = this;
    setTimeout(() => {
      localStorage.removeItem("searchJobsheet");
      _this.setFilter("pageJobsheet", _this.currentPage);
      _this.setFilter("searchJobsheet", _this.freeSearch);
    }, 250);
  }

  getFilter(name): any {
    if (localStorage.getItem(name)) {
      let val = JSON.parse(localStorage.getItem(name));

      return val;
    }
    return null;
  }

  loadFilters() {
    for (let i = 0; i < localStorage.length; i++) {

      if (localStorage.getItem(localStorage.key(i)) == "undefined" || localStorage.getItem(localStorage.key(i)) == undefined || localStorage.getItem(localStorage.key(i)) == null) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    this.freeSearch = this.getFilter("searchJobsheet");
    this.currentPage = (this.getFilter("pageJobsheet") ? this.getFilter("pageJobsheet") : 1);
    this.updateFilters();
  }

  setDateRange(event){
    let dates:Date[] = event;
    this.fromDate = dates[0];
    this.toDate = dates[1];
    this.updateFilters(true);
  }

  filterLoadTimeout: any
  updateFilters(skip?) {
    let _this = this;
    _this.searchParams = { per_page: environment.per_page, page: 1 };
    if (!skip) {
      _this.searchParams.page = _this.currentPage;
    }
    else {
      _this.currentPage = 1;
      _this.setFilter("pageJobsheet", 1);
    }

    console.log(_this.fromDate);
    console.log(_this.toDate);
    if(_this.fromDate || _this.toDate){
      if(!_this.fromDate){
        _this.fromDate = moment(_this.toDate).subtract(1,'d').toDate();
      }
      else{
        if(!_this.toDate){
          _this.toDate = moment(_this.fromDate).add(1,'d').toDate();
        }
      }
    }

    if (this.filterLoadTimeout) {
      clearTimeout(this.filterLoadTimeout);
    }
    this.filterLoadTimeout = setTimeout(() => {
  
      if(_this.installation){
        _this.searchParams['installation[]'] = _this.installation;
      }
      if(_this.client){
        _this.searchParams['client[]'] = _this.client;
      }
      if(_this.employee){
        _this.searchParams['employee[]'] = _this.employee;
      }
      if(_this.status){
        _this.searchParams['status[]'] = _this.status;
      }
      if(_this.fromDate && _this.toDate){
        _this.searchParams['date_from'] = moment(_this.fromDate).format();
        _this.searchParams['date_to'] = moment(_this.toDate).format();
      }
  
      if (_this.freeSearch) {
        _this.searchParams['q'] = _this.freeSearch;
      }
      _this.loadJobsheets();
    }, 500);
    this.saveFilters();
  }

  //List Dot Menu Bulk Selection

  @ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
  openBulk() {
    this.mdSlideUp.show()
  }

  mainToggle: boolean = false;
  activeActionTitle: string;

  handleButtonPress(event) {
    switch (event) {
      case "export-selected":
        this.activeActionTitle = "Export Selected";
        this.openBulk();
        break;
      case "export-all":
        this.activeActionTitle = "Export All";
        this.openBulk();
        break;
      default:
        console.log(event);
        break;
    }
  }

  activeRows:Map<number,any> = new Map<number,any>();
  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if(row['bulk_checked']){
      _this.activeRows.set(row['id'],row);
    }
    else{
      _this.activeRows.delete(row['id']);
    }
    this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }


  menuItems: any[] = [
    { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_jobsheet'] },
    { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_jobsheet'] },
  ];
  updateMenuItems(){
    for(let item of this.menuItems){
      if(this.activeRows.size == 0){
        if(item.action.includes('export-selected')){
          item.Disabled = true;
        }
      }
      else{
        if(item.action.includes('export-selected')){
          item.Disabled = false;
        }
      }
    }
    return this.menuItems;
  }

  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if(this.mainToggle == true){
        _this.activeRows.set(item.id,item);
      }
      else{
        _this.activeRows.delete(item.id);
      }
    }
    this.updateMenuItems();
  }

  submitToggle() {
    this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), 'jobsheet');
    this.activeRows = new Map<number,any>();
  }

  isOngoing(row){
    //console.log(row);
    return (row.actual_start_at && !row.actual_finish_at);
  }

  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  forceRefresh(dateEvent) {
    let _this = this;
    if(!this.fromDate || !this.toDate){
      return;
    }
    if(!this)
    if (dateEvent.date) {
      if (moment(new Date(dateEvent.date)).isBefore(_this.fromDate)) {
        dateEvent.date = _this.fromDate;
      }
      if (moment(new Date(dateEvent.date)).isAfter(_this.toDate)) {
        dateEvent.date = _this.toDate;
      }
    }
    // if (dateEvent.isStart == false) {
    //   _this.toDate = new Date(dateEvent.date)
    // }
    // else {
    //   _this.fromDate = new Date(dateEvent.date)
    // }

    if (!this.fromDate && !this.toDate) {
      return;
    }

    if(moment(this.fromDate).isAfter(moment(this.toDate))){
      this.toDate = moment(this.fromDate).add(1,'h').toDate();
    }
    if (this.fromPicker) {
      if (this.fromPicker.timePickerInner) {
        this.fromPicker.timePickerInner.updateTime();
      }
    }
    if (this.toPicker) {
      if (this.toPicker.timePickerInner) {
        this.toPicker.timePickerInner.updateTime();
      }
    }
    _this.cdr.detectChanges();
  }

  _disabledStartDate = (startValue) => {
     // if(_this.fromDate || _this.toDate){
      //   if(!_this.fromDate){
      //     _this.fromDate = moment(_this.toDate).subtract(1,'d').toDate();
      //   }
      //   else{
      //     _this.toDate = moment(_this.fromDate).add(1,'d').toDate();
      //   }
      // }
    if (!startValue) {
      this.fromDate = moment(this.toDate).subtract(1,'d').toDate();
    }
    if (!this.toDate) {
      this.toDate = moment(this.fromDate).add(1,'d').toDate();
    }
    // if (moment(startValue).dayOfYear() > moment(new Date()).dayOfYear()) {
    //   return true;
    // }
    //return startValue.getTime() > this._endDate.getTime();
    //return moment(startValue).dayOfYear() > moment(this.toDate).dayOfYear();
    return moment(startValue).isAfter(moment(this.toDate));
  };

  _disabledEndDate = (endValue) => {
    if (!endValue || !this.fromDate) {
      this.toDate = moment(this.fromDate).add(1,'d').toDate();
    }
    // if (moment(endValue).dayOfYear() > moment(new Date()).dayOfYear()) {
    //   return true;
    // }
    //return (moment(endValue).dayOfYear() < moment(this.fromDate).dayOfYear());
    return moment(endValue).isBefore(moment(this.fromDate));
  };

  fetchMoreUsers() {
    this.loadUsers();
  }

  loadUsers() {
    let _this = this;
    if (this.rearchedCapUser) {
      return true;
    }

    if (_this.timeoutUser) {
      clearTimeout(_this.timeoutUser);
    }

    if (_this.subscriptionUser) {
      _this.subscriptionUser.unsubscribe();
    }

    _this.timeoutUser = setTimeout(() => {

      _this.subscriptionUser = _this.userService.getAll({ per_page: 100, page: _this.lastPageUser }).subscribe(res => {

        if (!_this.users || _this.users.length == 0) {
          _this.users = [...res['data']];
        }
        else {
          _this.users = [..._this.users, ...res['data']];
        }
        if (+_this.lastPageUser == +res['meta']['last_page']) {
          _this.rearchedCapUser = true;
        }
        for (let client of _this.users) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email;
        }
        _this.lastPageUser++;
      },
        err => {
          console.log(err);
        })

    }, 350);

  }

  users: UserModel[] = [];
  timeoutUser: any;
  lastPageUser = 1;
  rearchedCapUser: boolean = false;
  subscriptionUser: Subscription;
  onSearchUser(event) {
    let term = event;
    let _this = this;
    this.lastPageUser = 1;
    this.rearchedCapUser = false;
    this.users = [];
    if (term == null || term == '') {
      this.loadUsers();
      return;
    }
    if (_this.timeoutUser) {
      clearTimeout(_this.timeoutUser);
    }
    _this.timeoutUser = setTimeout(() => {
      if (_this.subscriptionUser) {
        _this.subscriptionUser.unsubscribe();
      }
      this.userService.getAll({ q: term, per_page: 100, page: this.lastPageUser }).subscribe(res => {

        res['data'] = [...res['data']];
        _this.users = res['data'];

        for (let client of _this.users) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.email;
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  fetchMoreClients() {
    this.loadClients();
  }

  loadClients() {
    let _this = this;
    if (this.rearchedCapClient) {
      return true;
    }

    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }

    if (_this.subscription) {
      _this.subscription.unsubscribe();
    }

    _this.timeout = setTimeout(() => {

      _this.subscription = _this.clientService.getAll({ per_page: 100, page: _this.lastPageClient }).subscribe(res => {
        if (!_this.clients) {
          _this.clients = [...res['data']];
        }
        else {
          _this.clients = [..._this.clients, ...res['data']];
        }
        if (+_this.lastPageClient == +res['meta']['last_page']) {
          _this.rearchedCapClient = true;
        }
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + (client.address_1 ? ' - ' + client.address_1 : '');
        }
        _this.lastPageClient++;
      },
        err => {
          console.log(err);
        })

    }, 350);

  }

  clients: ClientModel[] = [];
  timeout: any;
  lastPageClient = 1;
  rearchedCapClient: boolean = false;
  subscription: Subscription;
  onSearchClient(event) {
    let term = event;
    let _this = this;
    this.lastPageClient = 1;
    this.rearchedCapClient = false;
    this.clients = [];
    if (term == null || term == '') {
      this.loadClients();
      return;
    }
    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      if (_this.subscription) {
        _this.subscription.unsubscribe();
      }
      this.clientService.getAll({ q: term, per_page: 100, page: this.lastPageClient }).subscribe(res => {
        _this.clients = res['data'];
        for (let client of _this.clients) {
          client['search_name'] = client.first_name + ' ' + client.last_name + ' - ' + client.address_1;
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }


  installations: InstallationModel[] = [];
  timeoutInstallation: any;
  lastPageInstallation = 1;
  rearchedCapInstallation: boolean = false;
  subscriptionInstallation: Subscription;
  onSearchInstallation(event) {
    let term = event;
    let _this = this;
    this.lastPageInstallation = 1;
    this.rearchedCapInstallation = false;
    this.installations = [];
    if (term == null || term == '') {
      this.loadClients();
      return;
    }
    if (_this.timeoutInstallation) {
      clearTimeout(_this.timeoutInstallation);
    }
    _this.timeoutInstallation = setTimeout(() => {
      if (_this.subscriptionInstallation) {
        _this.subscriptionInstallation.unsubscribe();
      }
      this.installationService.getAll({ q: term, per_page: 100, page: this.lastPageInstallation }).subscribe(res => {
        _this.installations = res['data'];
        for (let client of _this.installations) {
          client['search_name'] = client.reference + (client.address_1 ? ' - ' + client.address_1 : '');
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  fetchMoreInstallations() {
    this.loadInstallations();
  }

  loadInstallations() {
    let _this = this;
    if (this.rearchedCapInstallation) {
      return true;
    }

    if (_this.timeoutInstallation) {
      clearTimeout(_this.timeoutInstallation);
    }

    if (_this.subscriptionInstallation) {
      _this.subscriptionInstallation.unsubscribe();
    }

    _this.timeoutInstallation = setTimeout(() => {

      _this.subscriptionInstallation = _this.installationService.getAll({ per_page: 100, page: _this.lastPageInstallation }).subscribe(res => {
        if (!_this.installations) {
          _this.installations = [...res['data']];
        }
        else {
          _this.installations = [..._this.installations, ...res['data']];
        }
        if (+_this.lastPageInstallation == +res['meta']['last_page']) {
          _this.rearchedCapInstallation = true;
        }
        for (let client of _this.installations) {
          client['search_name'] = client.reference + (client.address_1 ? ' - ' + client.address_1 : '');
        }
        _this.lastPageInstallation++;
      },
        err => {
          console.log(err);
        })

    }, 350);

  }

  statuses: any[];
  loadStatuses() {
    let _this = this;
    this.jobsheetService.getStatuses().subscribe(res => {
      console.log(res);
      _this.statuses = res['data'];
    },
      err => {
        console.log(err);
      })
  }

}
