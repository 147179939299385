import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { HttpClientModule } from '@angular/common/http';

import { SecondarySidebarComponent } from './secondary-sidebar/secondary-sidebar.component';

import { QuickviewService } from './quickview/quickview.service';
import { ModalModule, TypeaheadModule } from 'ngx-bootstrap';

import { ParallaxDirective } from './parallax/parallax.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormGroupDefaultDirective } from './forms/form-group-default.directive';
import { ViewDirective } from './view/view.directive';

import { pgCollapseModule } from './collapse/collapse.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { pageContainer } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';

import { ListItemComponent } from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';
import { ClientListComponent } from '../../view/client/client-list/client-list.component';
import { DotMenuModule } from '../../view/components/dot-menu/dot-menu.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClientDetailComponent } from '../../view/client/client-detail/client-detail.component';
import { ButtonLoadingModule } from '../../view/components/btn-loading/btn-loading.module';
import { InstallationListComponent } from '../../view/installation/installation-list/installation-list.component';
import { InstallationDetailComponent } from '../../view/installation/installation-detail/installation-detail.component';
import { pgSelectModule } from './select/select.module';
import { pgDatePickerModule } from './datepicker/datepicker.module';
import { NguiMapModule } from '@ngui/map';
import { environment } from '../../../environments/environment';
import { ProgressModule } from './progress/progress.module';
import { PaymentComponent } from '../../view/components/payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { SubscriptionModalComponent } from '../../view/components/subscription-modal/subscription-modal.component';
import { CardModalComponent } from '../../view/components/add-card/add-card.component';
import { JobsheetDetailComponent } from '../../view/jobsheet/jobsheet-detail/jobsheet-detail.component';
import { JobsheetListComponent } from '../../view/jobsheet/jobsheet-list/jobsheet-list.component';
import { MessageModule } from './message/message.module';
import { TextMaskModule } from 'angular2-text-mask';
import { pgSwitchModule } from './switch/switch.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DomSanitizer } from '@angular/platform-browser';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@NgModule({
  imports: [
    MessageModule,
    TextMaskModule,
    pgSwitchModule,
    SignaturePadModule,

    CommonModule,
    ObserversModule,
    TypeaheadModule.forRoot(),
    ProgressModule,
    PerfectScrollbarModule,
    RouterModule,
    DotMenuModule,
    NgxStripeModule.forRoot("pk_test_51I69xgJ8aQygRtovt6v3WzpsJFT9vCzWN18v6kmzxlCUgGs7fCkh0N8SRF2mhpYyS1bbqlBV0yrI3J3LWkvhqfTr00x5b4J9gd"),
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ModalModule,
    ButtonLoadingModule,
    NgxPermissionsModule,
    pgSelectModule,
    pgDatePickerModule,
    NguiMapModule.forRoot({ apiUrl: environment.mapUrl })],
  declarations: [
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    ClientDetailComponent, ClientListComponent, InstallationListComponent, JobsheetDetailComponent, JobsheetListComponent,SafePipe,
    InstallationDetailComponent,PaymentComponent, SubscriptionModalComponent, CardModalComponent
  ],
  exports: [
    MessageModule,
    TextMaskModule,
    pgSwitchModule,
    SignaturePadModule,
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    pgCollapseModule,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    DotMenuModule, 
    ButtonLoadingModule,
    NgxPermissionsModule,
    ClientDetailComponent, ClientListComponent, JobsheetDetailComponent, JobsheetListComponent,SafePipe,
    InstallationListComponent,
    InstallationDetailComponent,
    PaymentComponent, SubscriptionModalComponent, CardModalComponent
  ],
  providers: [
    QuickviewService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
