import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/installation`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/installation/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/installation/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/installation`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/installation/`+id);
    }

    upload(id,formData){
      return this.http.post(`${environment.baseUrl}/installation/`+id+`/upload`,formData);
    }

    deleteFile(fileId){
      return this.http.delete(`${environment.baseUrl}/installation/document/`+fileId);
    }

    getLatLngDetails(lat,lng) {
      return this.http.get("https://nominatim.codeblack.mt/reverse.php", { params: { lat: lat,lon:lng, format: 'json' } })
    }

}
