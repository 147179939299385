import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from '../../../@pages/components/message/message.service';
import { Utils } from '../../../shared/services/utils.service';
import { SubscriptionService } from '../../../shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { PaymentComponent } from '../payment/payment.component';
import { SlotModel } from '../../../shared/models/slot.model';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit {

  @ViewChild('paymentComponent', { static: false }) paymentComponent: PaymentComponent;
  @ViewChild('subscriptionConfirm', { static: true }) subscriptionConfirm: ModalDirective;
  @Input() showModal: EventEmitter<any>;
  @Input() isLoading: boolean;
  @Input() manageMode: boolean = false;
  @Output() didSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private utils: Utils,
    private subscriptionService: SubscriptionService
  ) {

  }

  ngOnInit() {
    let _this = this;
    this.loadSlots();
    this.loadChargeRates();
    this.showModal.subscribe(res => {
      _this.subscriptionConfirm.show();
      _this.loadSlots();
    },
      err => {
        console.log(err);
      });
      // setInterval(() => {
      //   if(_this.paymentComponent){
      //     _this.isLoading = _this.paymentComponent.isLoading;
      //   }
      // },500);
  }

  setLoading(newLoadingState){
    this.isLoading = newLoadingState;
  }

  isSystemAdmin(): boolean {
    if (this.authService.getUserRole() == 1) {
      return true;
    }
    return false;
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  slotSub: Subscription;
  slotsToBuy: number = 1;
  slots: SlotModel;


  prices: {
    total_price: number;
    sub_total: number;
    vat_total: number;
  };

  rateSub: Subscription;
  rateTimeout: any;

  loadChargeRates(overrideSlots?) {
    let _this = this;
    if (this.rateSub) {
      this.rateSub.unsubscribe();
    }
    if (this.rateTimeout) {
      clearTimeout(this.rateTimeout);
    }
    this.isLoading = true;
    this.rateTimeout = setTimeout(() => {
      _this.rateSub = this.subscriptionService.getChargeRate({ slots: overrideSlots ? overrideSlots : this.slotsToBuy }).subscribe(res => {
        _this.prices = res['data']['rate'];
        _this.rateSub = null;
        _this.isLoading = false;
      },
        err => {
          console.log(err);
          _this.rateSub = null;
          _this.isLoading = false;
        });
    }, 350);
  }

  isPaying() {
    if (this.paymentComponent) {
      if (this.paymentComponent.paySub) {
        return true;
      }
    }
    return false;
  }

  completeTransaction() {
    this.loadSlots();
    this.messageService.remove();
    this.messageService.success("Transaction Complete.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    this.subscriptionConfirm.hide();
    this.paymentComponent.paySub = null;
    this.isLoading = false;
    this.didSubmit.emit(true);
  }

  getTotalPrice(): any {
    let val: number = this.prices.total_price;
    return val;
  }

  getSubtotal(): any {
    let val: number = this.prices.sub_total;
    return val;
  }

  getVat(): any {
    let val: number = this.prices.vat_total;
    return val;
  }

  formatNumber(value) {
    return this.utils.formatNumbersToFixedWithCommas(value);
  }

  newLicenseNumber:number;

  increment(){
    if(this.slots && this.slots.total_slots >= (this.newLicenseNumber + 1)){
      this.newLicenseNumber = this.newLicenseNumber + 1; 
    }
    else{
      this.messageService.error("You can't purchase licenses from this menu.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    }
  }

  decrement(){
    if(this.slots && this.slots.used_slots <= (this.newLicenseNumber - 1)){
      this.newLicenseNumber = this.newLicenseNumber - 1; 
    }
    else{
      this.messageService.error("You can't remove any more licenses.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    }
  }

  saveLicences(){
    let _this = this;
    this.subscriptionService.updateSubscription(this.newLicenseNumber).subscribe(res => {
      console.log(res);
      _this.loadSlots();
      _this.didSubmit.emit(true);
      _this.subscriptionConfirm.hide();
      _this.messageService.success("Subscription Updated Successfully.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
    },
    err => {
      _this.messageService.success("Subscription Update Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      console.log(err);
    })
  }

  loadSlots() {
    let _this = this;
    this.slotSub = this.subscriptionService.getSlots().subscribe(res => {
      _this.slots = res['data'];
      _this.newLicenseNumber = _this.slots.total_slots;
      if (_this.slots.available_slots == 0) {
        //TODO - something happens when 0 slots remaining.
      }
      console.log(_this.slots);
      _this.slotSub = null;
    },
      err => {
        console.log(err);
        _this.slotSub = null;
      })
    this.loadChargeRates();
  }

  processPayment() {
    let _this = this;
    if (!_this.paymentComponent.automaticPaymentFailed) {
      _this.paymentComponent.tryAutomaticPayment();
    }
    else {
      _this.paymentComponent.startManualPayment();
    }
  }

}
