import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(
        private http: HttpClient
    ) { }

    cardsUpdated:EventEmitter<any> = new EventEmitter<boolean>();

    getChargeRate(searchParams) {
        return this.http.get(`${environment.baseUrl}/chargerate`, { params: searchParams });
    }

    getSlots() {
        return this.http.get(`${environment.baseUrl}/payment/slots`);
    }

    startPaymentIntent(formData) {
        return this.http.post(`${environment.baseUrl}/payment/intent`,formData);
    }

    verifyPayment(formData){
        return this.http.post(`${environment.baseUrl}/payment/verify`,formData);
    }

    tryAutomaticPayment(formData) {
        return this.http.post(`${environment.baseUrl}/payment/charge`,formData);
    }

    updateSubscription(slots) {
        return this.http.post(`${environment.baseUrl}/subscription/update`,{slots:slots});
    }

    getAll(){
        return this.http.get(`${environment.baseUrl}/creditcard`);
    }

    create(formData){
        return this.http.post(`${environment.baseUrl}/creditcard`,formData);
    }

    delete(id){
        return this.http.delete(`${environment.baseUrl}/creditcard/`+id);
    }

    makeActive(id){
        return this.http.post(`${environment.baseUrl}/creditcard/`+id+`/active`,{});
    }

    getPayments(){
        return this.http.get(`${environment.baseUrl}/payment`);
    }

}
