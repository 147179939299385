import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleService } from './role.service';
import * as $ from 'jquery';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';

@Injectable()
export class RoleGuard implements CanActivate {
  returnUrl: string;

  constructor(
    private permissionService : NgxPermissionsService,
    private router: Router,
    private messageService : MessageService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log(next);
    let _this = this;
    // console.log("Accessed Can Activate");
    // console.log(state.url.split('/')[1]);


    // if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
    //   return false;
    // }
    if ($('form').hasClass("ng-touched") && $('form').hasClass("blockAuto")) {
      return false;
    }

    if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
      console.log("has touched form!");
      //console.log(state.url);
      
      let actions: ActionModel = {
        onAccept: {
          function: (event) => {
            $('form').addClass('exempt');
            console.log(next);
            console.log('/'+next.url[0].path);
            this.router.navigate(['/'+next.url[0].path]);
          },
          buttonText: "Yes"
        },
        onDecline: {
          function: (event) => {
          },
          buttonText: "No"
        }
      }
      this.messageService.sendNotification(`You have unsaved data. Are you sure you'd like to discard it?`, 'intervention', actions,0);
      return false;

      // if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
      //   setTimeout(() => {
      //     $('form').removeClass('blockAuto');
      //   }, 500);
      //   return false;
      // }
    }

    if (next.data.permission == 'view_dashboard') {
      if (next.url.length == 0) {
        this.router.navigate([`/dashboard`]);
      }
      return true;
    }

    console.log(this.permissionService.getPermissions());
    if (!this.permissionService.hasPermission(next.data.permission)) {
      console.warn('Access Denied. Navigated to default route');
      this.router.navigate([`/dashboard`]);
      return false;
    }

    else if (this.permissionService.hasPermission(next.data.permission)) {
      return true;
    }
    
    else {
      console.log(`Permission/Role not recognized`);
      return false;
    }

    return true;

    // if (userRole) {
    //   if (userRole.routeAccess[state.url.split('/')[1]]) {
    //     return true;
    //   }
    //   console.warn('Access Denied. Navigated to default route');

    //   const defaultRoute = this.rolesService.getAccess(userRoleId).defaultRoute;
    //   this.router.navigate([`/${defaultRoute}`]);
    //   return false;
    // } else {
    //   console.log(`User roles: [${userRole}] not recognized`);
    //   return false;
    // }

  }
}
